import React from 'react';
import {Table, Badge} from 'reactstrap';
import {Helmet} from "react-helmet-async";

export default function CoursesDetailsView() {
  return (
    <div id="about" className="about" >
    <Helmet>
      <title>Courses - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Courses - VJDepth - Distance Education Programme in Theology. Course details including eligibility, evaluation, instructions are available in this portal." />
        <meta name="keywords" content="Courses - VJDepth - Distance Education Programme in Theology" />
        <link rel="canonical" href="/courses" />
    </Helmet>

    <div className="container" data-aos="fade-up">
    <h1 className='text-center text-primary pt-4' style={{fontFamily:'Texturina, serif', fontWeight:'900'}}>Distance Education Programme in Theology (DEPTH)</h1>
<p>The Distance Education Programme in Theology is being launched from January 2013.</p>
{/*
<Row>
    <Col className='container'>
        <h3 className='text-primary'>
        <span><i className="fa fa-2x fa-cubes"></i>Features</span>
        </h3>
        <ul>
                        <li>Available in two Modes: Online Mode &amp; Correspondence Mode.</li>
                        <li>You can study theology staying at home, along with your current employment or occupation. It allows you to follow a pace to suit your own life-situation
                        </li>
                        <li>It is open to people from all walks of life and backgrounds, irrespective of age or gender.
                        </li>
                        <li>It is financially affordable to those who cannot afford the high cost of attending regular degree classes in a Theologate or University.
                        </li>
                        <li>Professors with proven scholarship and rich teaching experience have prepared the lessons.
                        </li>
                        <li>It allows for choosing from a number of optional lessons to suit the needs and interests of different groups: teachers, doctors, nurses, lawyers, political workers, business persons, catechists, etc.
                        </li>
                        <li>It offers you a Diploma in Theology on the successful completion of two phases of study which may require a minimum period of two years.
                        </li>
                    </ul>
    </Col>
    <Col className='container'>
        <h3 className='text-primary'>
          <img src={""} alt=""></img>  Eligibility
        </h3>
    </Col>
</Row>
  */}
  <div  className="row pt-3">
            <div  className="col-md-6 col-sm-6 col-lg-6 col-xs-12  wow fadeInDown animated" >
                <div  className="ss-content-box">
                    <h5  className="ss-section-title text-primary"><span><i  className="fa fa-2x fa-cubes p-2"></i>Features</span></h5>
                   <div className='card-view'>

                   
                    <ul   style={{textAlign:'justify', fontFamily:'Texturina, serif', fontWeight:'700', lineHeight:'28px'}}>
                        <li>Available in two Modes: Online Mode &amp; Correspondence Mode.</li>
                        <li>You can study theology staying at home, along with your current employment or occupation. It allows you to follow a pace to suit your own life-situation
                        </li>
                        <li>It is open to people from all walks of life and backgrounds, irrespective of age or gender.
                        </li>
                        <li>It is financially affordable to those who cannot afford the high cost of attending regular degree classes in a Theologate or University.
                        </li>
                        <li>Professors with proven scholarship and rich teaching experience have prepared the lessons.
                        </li>
                        <li>It allows for choosing from a number of optional lessons to suit the needs and interests of different groups: teachers, doctors, nurses, lawyers, political workers, business persons, catechists, etc.
                        </li>
                        <li>It offers you a Diploma in Theology on the successful completion of two phases of study which may require a minimum period of two years.
                        </li>
                    </ul>
                    </div>
                </div>  
            </div>
            <div  className="col-md-6 col-sm-6 col-lg-6 col-xs-12  wow fadeInDown animated" >
                <div  className="ss-content-box">

                    <h5  className="ss-section-title text-primary"><span><i  className="fa fa-2x fa-graduation-cap p-2"></i>Eligibility</span></h5>
                    <div className='card-view'>
                    <ul style={{textAlign:'justify', fontFamily:'Texturina, serif', fontWeight:'700', lineHeight:'28px'}}>

                        <li>The minimum requirement for a candidate is plus two level of education, and enough knowledge of English to follow the lessons and to write answers/assignments. (Presently Lessons are available only in English)</li>
                        <li>The course is open to all persons desiring to deepen their Christian Faith irrespective of age or gender. The focus, however, will be on the lay faithful.</li>

                    </ul>
                    </div>
                </div>
            </div>
        </div>
        <div  className="row pt-5">
            <div  className="col-md-6 col-sm-6 col-lg-6 col-xs-12  wow fadeInDown animated" >
                <div  className="ss-content-box ss-no-list-style">
                    <h5  className="ss-section-title text-primary"><span><i  className="fa fa-2x fa-user p-2"> </i>Online Registration </span><a href="/registration"> <Badge color="danger">New</Badge> </a></h5>
                    

                     <div className='card-view'>
                    <ul style={{textAlign:'justify', fontFamily:'Texturina, serif', fontWeight:'700', lineHeight:'28px'}}>
                        
                               <li>Candidates can register through this site by clicking on the link 'New Registration' and top bar. Fill all the details with correct e-mail id which will be the user id to use our online portal on successful registration. Candidate will receive an e-mail on registration with the details.</li>
                        <li>Once successfully completed the online application form, the candidate can print it using the print option available in the Profile area. 
                            </li>
                            <li>
                            The printed form should be signed by the applicant and should be sent to the address given below with the necessary documents.</li>
<br/>
               {/*}         <li>
                            <h3><span><i  className="fa fa-envelope"></i>Application by Post/in Person</span></h3>
                        </li>
                        <li>Application forms can be downloaded from
                            our <a href="http://www.vjdepth.in/courses#">here</a> or can be obtained from
                            Vidyajyoti office directly/by post. The
                            application forms duly filled up must be sent the address given below.
</li> */}
                
                    </ul>
                    </div>
                </div>
            </div>
            <div  className="col-md-6 col-sm-6 col-lg-6 col-xs-12  wow fadeInDown animated" >
                <div  className="ss-content-box ss-no-list-style">
                    <h5  className="ss-section-title text-primary"><span><i  className="fa fa-2x fa-id-card p-2"></i>Documents to be sent</span></h5>
                    <div className='card-view'>
                    <ul style={{textAlign:'justify', fontFamily:'Texturina, serif', fontWeight:'700', lineHeight:'28px'}}>
                        <li>Both for Online Applicants and Application by Post/Hand should be attached with:</li>
                        <li>1. Copies of previous certificates and
                            mark sheets as proof of academic qualification</li>
                        <li>2. Two recent photographs (passport size)</li>
                        <li>3. Cheque/Demand Draft for the prescribed registration fee. (For details for registration fee, click here.)</li>
                   
                        <li>Once we receive the application for registration the applicant will be
                            provided with an Enrolment Number, and in the case of online students, a user name and
                            password for accessing study materials</li>
                    </ul>  
                    </div>                 
                </div>
            </div>
        </div>
        <div  className="row pt-5">
            <div  className="col-md-6 col-sm-6 col-lg-6 col-xs-12  wow fadeInDown animated"  >
                <div  className="ss-content-box">
                    <h5  className="ss-section-title text-primary"><i  className="fa fa-2x fa-book p-2"></i><span>General Instructions</span></h5>
                    <div className='card-view'>
                    <ul style={{textAlign:'justify', fontFamily:'Texturina, serif', fontWeight:'700', lineHeight:'28px'}}>
                        <li>
                            DEPTh is designed as a life-enrichment study
                            programme leading to a Diploma in Theology.
                            It may be completed in two phases of 30 credits each.</li>
                        <li>
                            The courses are structured in a simple format to
                            help students to acquire a basic knowledge of key
                            aspects of the Christian scriptures and of Christian
                            theology.</li>
                        <li>
                            Lessons will be sent to the registered students in
                            installments either by post or online.</li>
                        <li>
                            The programme envisages setting up of Regional
                            Centres taking into account the number of students
                            from a region/State. Staff guides will be available at
                            Regional Centres for consultation and guidance.</li>
                        <li>
                            Contact classes will be held at Vidyajyoti, Delhi
                            and at the Regional Centres. Details will be
                            intimated in due time</li>

                    </ul>
                    </div>
                </div>  
            </div>
            <div  className="col-md-6 col-sm-6 col-lg-6 col-xs-12  wow fadeInDown animated"  >
                <div  className="ss-content-box">
                    <h5  className="ss-section-title text-primary"><i  className="fa fa-2x fa-check-circle-o p-2"></i><span>Evaluation System</span></h5>
                    <div className='card-view'>
                    <ul style={{textAlign:'justify', fontFamily:'Texturina, serif', fontWeight:'700', lineHeight:'28px'}}>
                        <li>Students will be required to submit a Summary Paper in prescribed format after completing each lesson as will be directed. It may be sent to the Course Director by post or through email.</li>
                        <li>There will be a written examination at the end of each phase covering all basic courses, and will be held at Vidyajyoti, Delhi, and at the Regional Centres.</li>
                    </ul>
                    </div>
                </div>
            </div>

        </div>
        <div  className="row pt-5">
            
            <div  className="col-md-6 col-sm-6 col-lg-6 col-xs-12  wow fadeInDown animated"  >
                <div  className="ss-content-box">
                    <h5  className="ss-section-title text-primary"><i  className="fa fa-2x fa-rupee p-2"></i><span>Fees for applicants from countries other than India</span></h5>
                  <Table responsive>
                  <thead><tr><th>Details</th><th>Fees</th></tr></thead>
                        <tbody>
                            <tr><td>Consolidated fees (Registration fee, Course fee for 2 phases, Examination fee

                                    and Diploma fee):</td><td>250 US Dollars</td></tr>

                        </tbody>
                 
                 </Table> 
                   
                 {/*   <table  className="table table-bordered table-condensed table-striped ss-table-fancy">
                        <thead><tr><th>Details</th><th>Fees</th></tr></thead>
                        <tbody>
                            <tr><td>Consolidated fees (Registration fee, Course fee for 2 phases, Examination fee

                                    and Diploma fee):</td><td>250 US Dollars</td></tr>

                        </tbody>
                    </table>
                            */}
                    <p>Please make your payment to the following bank account</p>
                    <dl  className="dl-horizontal">
                        <dt style={{ backgroundColor:"lightblue"}}>Account holder’s name</dt>
                        <dd>Jiv Prakash Vidyapeeth</dd>
                        <dt style={{ backgroundColor:"lightblue"}}>Bank name</dt>
                        <dd>Canara Bank</dd>
                        <dt style={{ backgroundColor:"lightblue"}}>Branch</dt>
                        <dd>St. Xavier’s School Branch,Raj Niwas Marg, Delhi – 54</dd>
                        <dt style={{ backgroundColor:"lightblue"}}>Account No.</dt>
                        <dd>91152010001158</dd>
                        <dt style={{ backgroundColor:"lightblue"}}>SWIFT Code</dt>
                        <dd>SYNBINBB126</dd>
                    </dl>
                    <p>Please intimate us with details after you remit the amount in the bank by Email -
                        director@vjdepth.in</p>
                </div>
                <div  className="ss-content-box">
                    <h5  className="ss-section-title text-primary"><i  className="fa fa-2x fa-address-card p-2"></i><span>Communication</span></h5>
                    <p>Completed application form and all communications should be directed to:</p>
                    <div className='card p-4' style={{width:'90%'}}>
                         <p>The Director</p>
                        <p>Distance Education</p>
                        <p>Vidyajyoti College of Theology</p>
                        <p>4-A, Rajniwas Marg</p>
                        <p>Delhi – 110054</p>
                    </div>
                       
                  
                </div>  
            </div> 
            
           
               
        
        




            <div  className="col-md-6 col-sm-6 col-lg-6 col-xs-12  wow fadeInDown animated"  >
                <div  className="ss-content-box">
                    <h5  className="ss-section-title text-primary"><i  className="fa fa-2x fa-rupee p-2"></i><span>Fees for applicants from India</span></h5>
                    <p>The fee structure with effect from 01.01.2017 is the following (All amounts are in Indian Rupee)</p>
                    <Table responsive> 
                    <thead><tr><th>Details</th><th>Correspondence Mode</th></tr></thead>
                        <tbody>
                            <tr><td>Registration,Course Fee and Examination Fee - Phase 1</td><td>3600</td></tr>
                            <tr><td>Course Fee and Examination Fee-Phase 1</td><td>3400</td></tr>
                        </tbody><tfoot>
                            <tr><td>Total</td><td>7000</td></tr></tfoot>
                        
                    </Table> 
                    
                   
                    <p>Note:One could pay it separately, either at the beginning of each phase or together 
                        as Rs.7000/ (correspondence) at the beginning itself.</p>
                    <p>All payments to be done by DD/Cheque to be drawn in favour of “Vidyajyoti”
                        payable at Delhi. You can also make an online transfer – Details are given below</p>
                    <dl  className="dl-horizontal">
                        <dt style={{ backgroundColor:"lightblue"}}>Account holder’s name</dt>
                        <dd>VIDYAJYOTI</dd>
                        <dt style={{ backgroundColor:"lightblue"}}>Bank name</dt>
                        <dd>Canara Bank</dd>
                        <dt style={{ backgroundColor:"lightblue"}}>Branch</dt>
                        <dd>St. Xavier’s School Branch,Raj Niwas Marg, Delhi – 54</dd>
                        <dt style={{ backgroundColor:"lightblue"}}>Account No.</dt>
                        <dd>91152010000290</dd>
                        <dt style={{ backgroundColor:"lightblue"}}>IFSC Code</dt>
                        <dd>CNRB0019115</dd>
                    </dl>
                    <p>Please intimate us with details after you remit the amount in the bank by Email -

                        director@vjdepth.in</p>
                </div>
            </div> 
        </div>
      


</div>
    </div>
  )
}
