import React, { useState, useEffect} from 'react';
import axios from 'axios';
//import gal1 from'../hospital/about/a1.jpg';
//import gal4 from'../hospital/about/a1.jpg';
//import gal5 from'../hospital/about/a1.jpg';
//import gal2 from'../hospital/about/a2.jpg';
//import gal3 from'../hospital/about/a7.jpg';import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Col, Row } from 'react-bootstrap';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Carousel } from 'react-responsive-carousel';
//import SliderMain from './SliderMain';
import Preloader from './Preloader';
import {Helmet} from "react-helmet-async";

const config = require('../config.json');


/*}
const content =[
  {
   imgs: gal1
  }
]
const event1 = [
{img:gal1,title:'New Building',desc:'New Building for emergency is blessed and openened'}     
];

const event2 = [
  {img:gal1,title:'Prayer Service',desc:'New Building for emergency is blessed and openened'}       
];

const event3 = [
  {img:gal1,title:'ICM Sisters',desc:'New Building for emergency is blessed and openened'}     
];     */

export default function GallerySec() {
  
  const [galleryImages, setGalleryImages]=useState([]);
	const [collapsed, setCollapsed] = useState(true);
	const [selectedProject, setSelectedProject]= useState([]);
  const [loaded, setLoaded] = useState(false);
  
  //const [isOpen, setIsOpen] = useState(false);

    useEffect(() => { fetchGalleryImages();
      const timer = setTimeout(() => setLoaded(true), 2000);
    return () => clearTimeout(timer);
     

  //testing();    
  }, []);

/*	const testing =()=>{
  console.log("Testing");
  const abc="images/projects/Balanagar#Completed#G5#04-06-2022/p1.jpg";
  const t1=encodeURIComponent(abc);
  const array1=abc.split("/");
  console.log("url : ", t1);
  console.log("array : ", array1);
}*/

const fetchGalleryImages =async()=>{
      try{
         // console.log("Testing");
        //  const session = await Auth.currentSession();
        //  const access_token=session.idToken.jwtToken;
          const fileName='images/gallery/';
          const bucketName="images.vjdepth.in";
//      await axios.get(`${config.api.invokeUrl}/images/projects?fileName=${fileName}&bucketName=${bucketName}`,{
        await axios.get(`${config.api.invokeURL}/dashboard/images/gallery?fileName=${fileName}&bucketName=${bucketName}`,{
          headers: {
           // Authorization: access_token,
            'x-api-key': config.api.key
          }}, 
      ).then((response)=>{
          //console.log(response);
          setGalleryImages(response.data);
          //setDispMsg(response.data.length + " - Images are found in your Gallery");
      }).catch((error)=>{
          console.log("Error:",error.message);
          //setDispMsg(error.message);
      })
  }catch(error){
  console.log(error.message);
      //setDispMsg(error.message);
  }
}

const assignProject =(event, item)=>{
  //console.log("test");
	event.preventDefault();
	//console.log("item : ", item);
	setSelectedProject(item.fileName);
	toggle();
}

    const toggle = () => {
      //console.log("clicked");
        setCollapsed(!collapsed);
    }

    
    return (
      
      <div className='container'> 
      <Helmet>
      <title>Gallery - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Gallery - VJDepth - Distance Education Programme in Theology." />
      <meta name="keywords" content="Gallery - VJDepth - Distance Education Programme in Theology" />
      <link rel="canonical" href="/gallery" />
    </Helmet>

         <Preloader show={loaded ? false : true} />
          <Modal  fullscreen isOpen={!collapsed} style={{top:'0%'}}>
          <ModalHeader
          close={<button className='btn bg-white' onClick={toggle}>X</button>}
          toggle={toggle}/>
          <ModalBody>
          <Carousel stopOnHover={true} dynamicHeight={true} infiniteLoop={true} autoPlay={true} showThumbs={false} showArrows={true} showIndicators={false} showStatus={false}>
          {selectedProject.map((img1, index)=>(
          <img key={index} src={`${config.s3.invokeUrl}${img1}`} alt="Avatar" style={{width:'70%'}} />
          ))} 
          </Carousel>

          </ModalBody>
        </Modal>
        
        <div className="section-title">
        <h1 className='text-center text-primary pt-4' style={{fontFamily:'Texturina, serif', fontWeight:'900'}}>Gallery</h1>
          <br/>
      
        
        <Row>
        {galleryImages.map((item, index)=>
        <Col md={4} key={index}>
        <div  style={{ justifyContent:'center', textAlign:'center', color:'black'}}>
            <div  key={index}  style={{paddingLeft:'0px', paddingRight:'0px'}} >
                        <div className='proj' onClick={(event)=>{assignProject(event, item)}}>
                      <div className="icon image-popup d-flex justify-content-center align-items-center" >
        <span className='icon-expand'>
                    <i className="fa fa-expand">
                    </i>
                    </span> 
                      </div>
                      <h3 className="heading" style={{color: 'black', fontFamily:'Texturina, serif', fontWeight:'900'}}> {item.projectName}</h3>
                        <Carousel infiniteLoop={true} autoPlay={true} showThumbs={false} showArrows={true} showIndicators={false} showStatus={true}>
            {(item.fileName).map((img1,index)=>
            <div key={index}>
                          <img key={index} src={encodeURI(`${config.s3.invokeUrl}${img1}`)} alt='projects' style={{width: "400px", height: "400px", float:'midle', borderRadius:'30px'}}/>
              </div>  )}
              </Carousel>

                <div style={{fontFamily:'Texturina, serif'}}>{item.projectDate} </div>

        </div>

       </div>
</div>

        
  
 
 </Col>)}
     

</Row>
{/*
  
  <div className='d-flex' style={{paddingBottom:'15px'}}>

<div  style={{ justifyContent:'center', textAlign:'center', color:'black'}}>
    {galleryImages.map((item, index)=>
    <div key={index} className="col-md-4  " style={{paddingLeft:'0px', paddingRight:'0px'}} >
                 <div className='proj'>
 
               <div className="icon image-popup d-flex justify-content-center align-items-center" onClick={(event)=>{assignProject(event, item)}}>
<span className='icon-expand'>
             <i className="fa fa-expand">
             </i>
             </span> 
</div>
               <h3 className="heading" style={{color: 'black'}}> <a href="/">{item.projectName}</a></h3>
                 <Carousel infiniteLoop={true} autoPlay={true} showThumbs={false} showArrows={true} showIndicators={true} showStatus={true}>
    {(item.fileName).map((img1,index)=>
    <div key={index}>
                  <img src={encodeURI(`${config.s3.invokeUrl}${img1}`)} alt='projects' style={{width: "400px", height: "400px", float:'midle', borderRadius:'30px'}}/>
       </div>  )}
       </Carousel>

         <div><a href="/">{item.projectDate} </a></div>

</div>

       </div>)} 
</div>
        
  
    </div> */}
  

        
     </div> </div>
    )
}
