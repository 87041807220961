import React, { useEffect, useState } from 'react';
import {Row, Col, Badge} from 'reactstrap';
//import { Carousel } from 'react-bootstrap';
//import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from 'react-bootstrap/Carousel';

//import infoSampleImg from '../assets/img/infoSampleImg.jpg';
import Marquee from 'react-fast-marquee';
//import logo from'../assets/vjdepth-logo.png';
import axios from 'axios';
import SliderMain from './SliderMain';
import { faithMsgData, scrollMsgData } from '../ProData';
const config = require('../config.json');

/*const newsData={news:'Important News today'};
const messageData={
titleHead:'Faith Alive', 
titleMsg:'Share the Best Seed', 
msgText:[<div className='list-unstyled' style={{textAlign:'justify'}}><li>There once was a farmer who grew the most excellent wheat. Every season he won the award for the best wheat in his county.</li>  
<li>A wise woman came to him to ask him about his success. </li>
  <li>  He told her that the key was sharing his best seed with his neighbors so they could plant the seed as well. 
   </li><li> The wise woman asked, “How can you share your best wheat seed with your neighbors when they compete with you every year?" “That’s simple,” the farmer replied. 
   </li><li> “The wind spreads the pollen from everyone’s wheat and carries it from field to field. 
   </li> <li> If my neighbors grew inferior wheat, cross-pollination would degrade everyone’s wheat, including mine. 
    </li> <li>If I’m to grow the best wheat, I must help my neighbors grow the best wheat as well.” 
   </li> <li> This is not only excellent advice for growing the best crops, but also great advice for how to live your life. 
   </li> <li>  If you want to live a meaningful and happy life, help others find happiness. 
   </li> <li>  Remember: The value of your life is measured by the lives you touch with love, kindness, respect, and hope.</li>
</div>]
}
const infoDesk1={title:'3rd Annual Seminar & Convocation of the DEPTh',
msg:[<div><p></p></div>],img:{infoSampleImg}, onlineLink:[<a>Click here to open the link</a>]}
const infoDesk2={title:'Admission News',
msg:[<div><p>Before you do the online Application,kindly send a mail and get approval from the Director
   </p></div>],img:{logo}, onlineLink:[<a>director@vjdepth.in</a>]}*/

export default function InfoDesk() {

  //const [activeInfo, setActiveInfo]=useState([]);
  const [flashNews, setFlashNews]=useState([]);
  const [scrollInfo, setScrollInfo]=useState(scrollMsgData);
  const [faithInfo, setFaithInfo]=useState(faithMsgData);

  useEffect(()=>{
    fetchActiveInfo();
  },[])

  const generateDate = async () => {
    const current_datetime = new Date();
    const date_year = (current_datetime.getFullYear()).toString();
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    var todayDate="";
    const tDate = todayDate.concat(date_year,"-",month_value,"-", date_value);
    //setUserId(accountNo);
    return tDate.toString();
    //console.log("acc : ", accountNo);
    }  

  const fetchActiveInfo =async()=>{
    try{
      //  setLoaded(false);
       //console.log("Testing");
       //const session = await Auth.currentSession();
       //const access_token=session.idToken.jwtToken;
        const startDate=await generateDate();
       // console.log("start Date : ", startDate);
        const clientID="VJDepth";
    await axios.get(`${config.api.invokeURL}/dashboard/newsinfo?clientID=${clientID}&startDate=${startDate}`,{
        headers: {
         // Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
        console.log(response);
        //setActiveInfo(response.data);
        const tempValue=response.data;
        var tFlash=[];
        var tScroll=[];
        var tFaith=[];
        for(const item in tempValue){
          if(tempValue[item].category==="Flash News"){
            tFlash.push(tempValue[item].flashNews);
          }
          if(tempValue[item].category==="Scroll Message"){
            tScroll.push(tempValue[item].scrollMsg);
          }
          if(tempValue[item].category==="Faith Message"){
            tFaith.push(tempValue[item].faithMsg);
          }
        }
        setFlashNews(tFlash);
        if(tScroll.length>0){
          setScrollInfo(tScroll);
        }
        //console.log("Scroll msg : ", tScroll);
        if(tFaith.length>0){
          setFaithInfo(tFaith);
        }
       // setGalleryImages(response.data);
     
        //setDispMsg(response.data.length + " - Images are found in your Gallery");
    }).catch((error)=>{
        console.log(error);
        //setDispMsg(error.message);
    })
}catch(error){
console.log(error);
    //setDispMsg(error.message);
}
}
  
    return( <div>
    <div id="about" className='m-lg-4'  style={{paddingTop:'0px'}}>
      <div  data-aos="fade-up">
      <Marquee gradient={false} style={{width:'100%'}} speed="30" pauseOnHover="true" > 
           {flashNews.map((item, index)=>
 <h3 key={index} ><Badge color="success" pill>New</Badge> {item} </h3>)} </Marquee>

     <Row>  
           <Col  lg={8} md={12} style={{ height:'600px', borderRadius:'0px'}} >
           <Marquee gradient={false} speed="100" pauseOnHover="true" >
            <SliderMain scrollInfo={scrollInfo}/>
           </Marquee>
           {/*} <Carousel showArrows infiniteLoop>
               <SliderMain scrollInfo={scrollInfo}/>
           </Carousel>*/}
           </Col>  

           <Col   lg={4} md={12}  style={{ height:'600px', border:'0px solid white', borderRadius:'30px', backgroundColor:'#97d1f6', color:'#011254'}} >
           <Carousel fade>
           {faithInfo.map((item, index)=>
      <Carousel.Item>
        <div  key={index} style={{textAlign:'justify'}}>
           <h1 className='text-center'>{item.head}</h1>
           <h4 className='text-center'>{item.title}</h4>
          <p >{item.message}</p></div>
      </Carousel.Item>)}
    </Carousel>
         
         {/*faithInfo.map((item, index)=>
           <div  key={index} style={{textAlign:'justify'}}>
           <h1 className='text-center'>{item.head}</h1>
           <h4 className='text-center'>{item.title}</h4>
          <p >{item.message}</p></div>)*/}
        
         </Col>
      </Row>
      </div>
    </div>

    </div>)
  
}
