import React, { Component } from 'react';
//import { Col, Row } from 'reactstrap';
import {Helmet} from "react-helmet-async";
import { NavHashLink as Link } from 'react-router-hash-link';

export default class FooterSec extends Component {
  render() {
    return <div>
    <Helmet>
      <title>VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="VJDepth - Distance Education Programme in Theology. Distance Education Programme in Theology (DEPTh) The Second Vatican Council had a dream when it wrote: “Let the laity, therefore, diligently apply themselves to a more profound knowledge of revealed truth…” (LG, 35). Sharing in the same dream Vidyajyoti has initiated the new Distance Education Programme in Theology (DEPTh) primarily for the lay faithful. It welcomes all who desire to deepen their Christian faith, and search for avenues of deeper participation in the mission of the Church." />
        <meta name="keywords" content="VJDepth - Distance Education Programme in Theology" />
    </Helmet>

        
<footer id="footer">

<div className="footer-top">
  <div className="container">
  <div className="row">

  {/*    <div className="col-lg-3 col-md-6">
        <div className="footer-info">
          <h3>DEPTH</h3>
          <p>
          Vidyajyoti College of Theology, 4A, Raj Niwas Marg,Civil Lines, <br/>Delhi – 110 054,India.
           <br/>
            <strong>Phone:</strong> +011 2394 3556, +011 2394 7609<br/>
            <strong>Email:</strong> director@vjdepth.in<br/>
            
            </p>
                      </div>
  
     </div>*/}
   {/*  <div className="col-lg-5 col-md-6">
        <div className="footer-info">
        <h3>Have Any Query?</h3>
       <Row>
         <Col xs={2} lg={2}>
         <div className="social-links mt-3">
        <a href="#" className="twitter"><i className="fa fa-phone"></i></a></div>
         </Col>
         <Col>
         <strong>Contact over phone</strong> 
        <p>+919205210426 between 7.45AM-3.00PM Monday to Friday </p>
         </Col>
       </Row>
       <Row>
         <Col xs={2} lg={2}>
         <div className="social-links mt-3">
        <a href="#" className="twitter"><i className="fa fa-phone"></i></a></div>
         </Col>
         <Col>
         <strong>Contact over phone</strong> 
        <p>+919205210426 between 7.45AM-3.00PM Monday to Friday </p>
         </Col>
       </Row>
       <Row>
         <Col xs={2} lg={2}>
         <div className="social-links mt-3">
        <a href="#" className="twitter"><i className="fa fa-phone"></i></a></div>
         </Col>
         <Col>
         <strong>Contact over phone</strong> 
        <p>+919205210426 between 7.45AM-3.00PM Monday to Friday </p>
         </Col>
       </Row>

        </div>

      </div> 
                
          <div className="social-links mt-3">
            <a href="#" className="twitter"><i className="fa fa-twitter"></i></a>
            <a href="#" className="facebook"><i className="fa fa-facebook"></i></a>
            <a href="#" className="instagram"><i className="fa fa-instagram"></i></a>
            <a href="#" className="google-plus"><i className="fa fa-youtube"></i></a>
            <a href="#" className="linkedin"><i className="fa fa-linkedin"></i></a>
          </div>
    */}
  
        </div>
        

<div >
  <div className="copyright">
  <Link  to="/policies" ><p className="footer-terms">Our Policies </p></Link>

    &copy; 2022 Copyright <strong><span>Idntica</span></strong>. All Rights Reserved
  </div>
  
 
  </div> </div>
       </div>
       
       </footer>
    </div>;
  }
}
