import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Card} from 'react-bootstrap';

import "react-responsive-carousel/lib/styles/carousel.min.css";
//import { Carousel } from 'react-responsive-carousel';
const config = require('../config.json');

export default function SliderMain(props) {
  const [galleryImages, setGalleryImages] = useState([]);
  const {scrollInfo}=props;

  useEffect(()=>{
    fetchSliderImages();
   // console.log("props scrollInfo :", scrollInfo);
  },[])

  const fetchSliderImages =async()=>{
    try{
       // console.log("Testing");
      //const session = await Auth.currentSession();
      //const access_token=session.idToken.jwtToken;
      const fileName='images/slider/';
      const bucketName="images.vjdepth.in";

    await axios.get(`${config.api.invokeURL}/dashboard/images/slider?fileName=${fileName}&bucketName=${bucketName}`,{
        headers: {
          //Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
      const finalList=[];
     // console.log("Res : ", response);
      if(response.data.length>0){
        for (const i in response.data){
          const tValue=response.data[i];
          const tPathName=tValue.split("/");
          const tBreakUp=tPathName[2];
          const tempValue={};
          tempValue.fullPathName=response.data[i];
          tempValue.fileName=tBreakUp;
         
         // console.log("Temp Value : ", tempValue);
          //const tdoctorName=tBreakUp[1];
          //const tempDName=tdoctorName.split(".");

          //tempValue.doctorName=tempDName[0];
         // tempValue.projectSize=tBreakUp[2];
          //tempValue.projectDate=tBreakUp[3];
          finalList.push(tempValue);
          
        }
      }
        //console.log("Res : ", finalList);
       // console.log("test :", config.s3.invokeUrl+finalList[0].fullPathName);
        setGalleryImages(finalList);
        //setDispMsg(response.data.length + " - Images are found in your Gallery");
    }).catch((error)=>{
        console.log(error.message);
        //setDispMsg(error.message);
    })
}catch(error){
console.log(error.message);
    //setDispMsg(error.message);
}
}

  return (
    <>
      <div className="col" style={{display: "flex", height:'610px'}}> 
        {scrollInfo.map((item, index)=>
        <Card key={index} className='m-2 p-2 text-center' style={{width:"500px", alignItems:'center'}}>
            <h3 className='text-center text-primary'>{item.title}</h3>
            <img key={index} alt="slider" src={encodeURI(`${config.s3.invokeUrl}images/infoDesk/${item.image}`)} style={{width:'300px', height:'300px'}} className='m-0 p-0 img-fluid rounded' />
        <h3 className='text-center text-primary'>{item.message}</h3>
        <p>{item.email}</p>
        <p>{item.link}</p>
         </Card> )}
        {galleryImages.map((images, index)=>  
            <img key={index} alt="slider" src={encodeURI(`${config.s3.invokeUrl}${images.fullPathName}`)} style={{height:'600px'}} className='m-0 p-0 img-fluid rounded' />
        )}
   </div>
    </>
  )
}
