import React, { Component } from 'react';
import {  useNavigate } from "react-router-dom";
import app from '../assets/img/androidapp.png'


export default function HeaderTop(){
  const navigate=useNavigate();
    return (
    <div id="topbar" className="d-flex align-items-center fixed-top " >
    <div className="container d-flex align-items-center justify-content-center justify-content-md-between">
      <div className="align-items-center d-none d-md-flex">
      <i className="fa fa-address-card"></i> <span style={{paddingRight:'5px'}}>Vidyajyoti College of Theology, 4A, Raj Niwas Marg,Civil Lines,Delhi – 110 054,India. </span>
       
      </div>
      <div className="d-flex align-items-center">
        <i className="fa fa-envelope"></i> director@vjdepth.in
      </div>
      <div className="d-flex align-items-center">
        <i className="fa fa-phone"></i> +91-92052 10426
      </div>
      <div className="d-flex align-items-center p-2" >
      
        <img src={app} alt=''  style={{width:"110px"}} onClick={event=>navigate("/contactus")}/>
      </div>
    </div>
    </div>
    )
}
