import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import config from './config';
import Amplify from 'aws-amplify';


Amplify.configure ({
  Auth: {
      mandatorySignId: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
  }
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
        <HelmetProvider>
      <App />
      </HelmetProvider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
