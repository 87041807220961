import React from 'react';
import {Helmet} from "react-helmet-async";
import { useNavigate } from "react-router-dom";

const magazinePoster = require("../../assets/banner.jpg")
const dir = require("../../assets/director-welcome.jpg")

export default function MagazineSection() {
    const navigate = useNavigate();

    const handleMagazineRoute=(event)=>{
        event.preventDefault();
        //console.log("route");
        navigate('/magazine/2023');
    }

  return (
    <>
    <Helmet>
      <title>VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="VJDepth - Distance Education Programme in Theology. Distance Education Programme in Theology (DEPTh) The Second Vatican Council had a dream when it wrote: “Let the laity, therefore, diligently apply themselves to a more profound knowledge of revealed truth…” (LG, 35). Sharing in the same dream Vidyajyoti has initiated the new Distance Education Programme in Theology (DEPTh) primarily for the lay faithful. It welcomes all who desire to deepen their Christian faith, and search for avenues of deeper participation in the mission of the Church." />
        <meta name="keywords" content="VJDepth - Distance Education Programme in Theology" />
        <link rel="canonical" href="/" />
    </Helmet>
    <div className="container">
  
    <div className="container"><button onClick={event=>handleMagazineRoute(event)}>
    <img alt="magazine" src={dir}  className='m-0 p-0 img-fluid' onClick={event=>handleMagazineRoute(event)}/>
    </button>
    </div>
    {/*}
    <h3 className='text-primary text-center'> 2023 - Our First E-Magazine by our Lay-Faithful</h3>
    <div className="container"><button onClick={event=>handleMagazineRoute(event)}>
    <img alt="magazine" src={magazinePoster}  className='m-0 p-0 img-fluid' onClick={event=>handleMagazineRoute(event)}/>
    </button>
  </div> */}
    </div>
    </>
  )
}
