import './App.css';
import './css/style.css';
import './css/_preloader.scss';

import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderTop from './Components/HeaderTop';
import NavbarTop from './Components/NavbarTop';
import AboutSec from './Components/AboutSec';
import CoursesDetailsView from './Components/CoursesDetailsView';
import ContactUsSec from './Components/ContactUsSec';
import FooterSec from './Components/FooterSec';
import GallerySec from './Components/GallerySec';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import RegistrationNew from './Components/RegMain/RegistrationNew';
import RegStatusLogin from './Components/RegMain/RegStatusLogin';
import Bottombar from './Components/bottombar/Bottombar';
import {Helmet} from "react-helmet-async";
//import SignIn from './Components/auth/Signin';
//import ForgotPassword from './Components/auth/ForgotPassword';
//import ViewMagazine from './Components/magazine/ViewMagazine';
import ViewMagazine1 from './Components/magazine/ViewMagazine1';
import Policy from './Components/policies/Policy';

function App() {
  return (
    <div className="App">
      <Helmet>
      <title>VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="VJDepth - Distance Education Programme in Theology. Distance Education Programme in Theology (DEPTh) The Second Vatican Council had a dream when it wrote: “Let the laity, therefore, diligently apply themselves to a more profound knowledge of revealed truth…” (LG, 35). Sharing in the same dream Vidyajyoti has initiated the new Distance Education Programme in Theology (DEPTh) primarily for the lay faithful. It welcomes all who desire to deepen their Christian faith, and search for avenues of deeper participation in the mission of the Church." />
        <meta name="keywords" content="VJDepth - Distance Education Programme in Theology" />
        <link rel="canonical" href="/" />

    </Helmet>

      <div className='navbar-bg' style={{marginBottom:'40px'}}>
        <HeaderTop />
  </div> 
  <NavbarTop/>
  <Routes>
      <Route path="/" element={<Home />} />
      <Route path="contactus" element={<ContactUsSec />} />
      <Route path="aboutus" element={<AboutSec />} />
      <Route path="courses" element={<CoursesDetailsView />} />
      <Route path="gallery" element={<GallerySec/>} />
     {/*} <Route path="signin" element={<SignIn/>} />
      <Route path='signin' component={() => { 
      window.location.replace = 'https://students.vjdepth.in/#/dashboard/sign-in'; 
      return null;}}/>*/}
      {/*<Route path="forgotpassword" element={<ForgotPassword/>} />*/}
      <Route path="registration" element={<RegistrationNew/>} />
      <Route path="registration-status" element={<RegStatusLogin/>} />
      <Route path="magazine/2023" element={<ViewMagazine1 />} />
      <Route path="/policies" element={<Policy />} />
    </Routes>
<FooterSec/>
<div className="navbar-mobile">
          <Bottombar />
          </div>
          <Helmet>
      <title>VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="VJDepth - Distance Education Programme in Theology. Distance Education Programme in Theology (DEPTh) The Second Vatican Council had a dream when it wrote: “Let the laity, therefore, diligently apply themselves to a more profound knowledge of revealed truth…” (LG, 35). Sharing in the same dream Vidyajyoti has initiated the new Distance Education Programme in Theology (DEPTh) primarily for the lay faithful. It welcomes all who desire to deepen their Christian faith, and search for avenues of deeper participation in the mission of the Church." />
        <meta name="keywords" content="VJDepth - Distance Education Programme in Theology" />
        <link rel="canonical" href="/" />

    </Helmet>

    </div>
  );
}

export default App;
