import React from 'react';
import {Helmet} from "react-helmet-async";
import {Row, Col, Badge} from 'reactstrap';
import img from'../assets/img/aboutus-goforth.png';
import theolgicalImg from'../assets/img/theological.png';

export default function AboutSec() {
  return (
    <div id="about" className="aboutUs" >
    <Helmet>
        <title>About Us - VJDepth - Distance Education Programme in Theology</title>
        <meta name="description" content="About Us - VJDepth - Distance Education Programme in Theology. Our address, contact details and regional center details are available." />
        <meta name="keywords" content="About Us - VJDepth - Distance Education Programme in Theology" />
		    <link rel="canonical" href="/aboutus" />

    </Helmet>

    <div className="container" data-aos="fade-up">
<h1 className='text-center text-secondary pt-4' style={{fontFamily:'Texturina, serif', fontWeight:'900'}}>VJ-DEPTh History</h1>
<h3 className='text-center text-primary' style={{textAlign:'justify', fontFamily:'Texturina, serif', fontWeight:'700', lineHeight:'30px'}}>CHURCH GOING FORTH...</h3>
<div>
<Row>  
<Col  lg={4} md={12} >
<img src={img} alt='projects' style={{width: "400px", height: "400px", float:'midle', borderRadius:'30px'}}/>
</Col>
<Col  lg={8} md={12} >
<div style={{textAlign:'justify', fontFamily:'Texturina, serif', lineHeight:'30px'}}>
“VJ-DEPTh” stands for Vidyajyoti Distance Education Programme in Theology.  Recognizing the growing thirst of Laity to deepen their faith, Fr. John Mesquita SJ took the initiative with the help of the faculty of  Theology at Vidyajyoti, Delhi. Vidyajyoti staff members wrote down lessons on various subjects of theology and scripture, in order to initiate correspondence course in Theology. The faculty also took up the responsibility to evaluate those answer sheets submitted by the parishioners. Eventually it was given the title “Do it by Yourself”. Thus, the seed of Distance Education Programme in theology was born about 30 years back. 
</div>
<br />
<div style={{textAlign:'justify', fontFamily:'Texturina, serif', lineHeight:'30px'}}>The present form of VJ-DEPTh is a new version, which is growing in leaps and bounds by the grace of God.  Dr. Fr. P.T. Mathew SJ took over as its director from 2013 and efficiently updated the existing system and made it relevant.  Prof. Dr. Fr. Rajkumar Joseph SJ took over as its director in 2016 and brought in fresh initiatives with passionate-personal approach to make it more learner-friendly with Sunday online classes to reach out to all parts of the nation and abroad. 
</div>
<br />
<div style={{textAlign:'justify', fontFamily:'Texturina, serif', lineHeight:'30px'}}>Currently VJ-DEPTh has about 363 students enrolled.  In this 67 of them hail from Malaysia, Dubai, Mauritius, Zurich and Sri Lanka.  So far about 182 have graduated from all over India and abroad.   In another few months the second batch of about 55 learners from Malaysia will be completing successfully.  Thus VJ-DEPTh is regularly sending out Theology Graduates into the wider Church.  
</div>
</Col></Row>
</div>
<br />

<h3 className='text-center text-primary'>Prominent  Features:</h3>
<div style={{textAlign:'justify', fontFamily:'Texturina, serif', lineHeight:'30px'}}>
<ul class="checklist">
  <li><i class="fa fa-check" aria-hidden="true"></i>   Regular Contact classes and Eucharist at regional levels</li>
  <li><i class="fa fa-check" aria-hidden="true"></i>  Interactive Student-friendly Website: www.vjdepth.in & WhatsApp forum for discussion. </li>
  <li><i class="fa fa-check" aria-hidden="true"></i> Regular online classes on alternate Sundays from 5.00pm to 7.00 pm.</li>
  <li><i class="fa fa-check" aria-hidden="true"></i> Annual Convocation ceremony with annual Seminar for Theological Education to Local Delhi Catholics.</li>
  <li><i class="fa fa-check" aria-hidden="true"></i> Yearly Laity Webinar on Papal documents </li>
  <li><i class="fa fa-check" aria-hidden="true"></i> VJ-DEPTh magazine (Totally Managed by the Laity for the Laity) to help the learners to reflect and contextualize by writing creatively and theologically.  </li>
</ul>
</div>
<br />

<h3 className='text-center text-primary'>How Does it Work?</h3>
<div style={{textAlign:'justify', fontFamily:'Texturina, serif', lineHeight:'30px'}}>
DEPTh is a full-fledged correspondence theology programme, which includes Theological, Scriptural,  Sacramental and Moral theology courses.  DEPTh is structured as “Diploma Programme in Theology”, requiring students to complete 80 credits within a minimum period of two to three years. It consists of two phases. Besides the study materials that are sent for each course, the students are encouraged to supplement their learning through personal reading, writing and visiting nearby libraries.  Through Online Sunday classes the learners get an opportunity to listen to expert professors on various theological themes and able to clarify their doubts and questions. 
</div>
<br />
<div style={{textAlign:'justify', fontFamily:'Texturina, serif', lineHeight:'30px'}}>
The learners are given a plan with deadline to complete each lesson. After reading, reflecting and personalization, they send their response sheets to the DEPTh Office which the Professors evaluate and thereafter give detailed personal feedback for each reflective-answer. The feedback helps the students to identify areas for improvement and encourages them in their further learning. Finally, at the end of each phase, they write mini-comprehensive exams both for Scripture and Systematic-Moral Theology near their own respective places. These exams make them integrate their knowledge and develop a synthesis of all their learning. 
</div>
<br />

<h3 className='text-center text-primary'>Goal: Theological Education to the Laity</h3>
<br />

<Row>  
<Col  lg={8} md={12} >
<div style={{textAlign:'justify', fontFamily:'Texturina, serif', lineHeight:'30px'}}>
When viewed through the lens of theological formation of the laity which Veritatis Gaudium envisages and encourages, DEPTh is an eminent expression and example of the ‘way forward’ through which, though in a small way, the process of theological learning and conscientizing of men and women with Christian and Social Thought can be facilitated so that they become agents of building the Reign of God and thereby, spread the joy of knowing the truth, which “is not an abstract idea but is Jesus himself” (Veritatis Gaudium 1). 
Thus, DEPTh puts in evidence the resources of the Faculty of Theology to prepare men and women who will become effective and enlightened witnesses and evangelizers, best suited to their pastoral exigencies and geo-political contexts in each local church.
</div>
<br />
<div style={{textAlign:'justify', fontFamily:'Texturina, serif', lineHeight:'30px'}}>
DEPTh is, therefore, designed to equip the laity to engage in the apostolate proper to them which “derives from their Christian vocation” (Apostolicam Actuositatem, 1) and to become an enlightened and evangelizing community of faithful, who are alert to the Spirit and mature in faith, and “exercise the mission which God entrusted to the Church to fulfil in the world” in and through witnessing, evangelizing and catechizing at the pastoral level, “each according to his or her particular condition” (CIC can.204 §1).
</div>
</Col>
<Col  lg={4} md={12} >
<img src={theolgicalImg} alt='projects' style={{width: "400px", height: "400px", float:'midle', borderRadius:'30px'}}/>
<h3 className='text-center'>Theology Reaching the Frontiers</h3>
</Col></Row>

</div>
    </div>
  )
}
