import React, { useEffect } from 'react';

export default function PhotoProof(props) {
    const inputRef = React.useRef();
    const { profileImage, setAlertDetails, setImageFile, alertDetails}=props;

useEffect(() => {
  //setImage(`${config.s3.imageURL}${profileImage}`);
 // console.log("profile image = ", `${config.s3.imageURL}${profileImage}`);
  
}, [profileImage])

   const handleFileSelection = (event)=>{
     event.preventDefault();
     if (event.target.files && event.target.files.length > 0) {
       setImageFile(event.target.files[0]);
       //console.log("selected file : ", event.target.files[0].name);
       var tprofile = document.getElementById("profile");
       tprofile.src=URL.createObjectURL(event.target.files[0]);
       setAlertDetails({
        ...alertDetails,
        style:"info",
        open:true,
        message:"File Selected - "+ event.target.files[0].name
    });   
   }else{
    setAlertDetails({
      ...alertDetails,
      style:"info",
      open:true,
      message:"No Files selected"
  });   
   }
  }

    return (
      <div style={{textAlign:"left"}}> 
        <p>  Attach Passport Photo: </p>      
       
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={handleFileSelection}
          //style={{ display: "none" }}
        />
        
       {/*} <span style={{backgroundColor:'primary', width:'120px', height:'120px', borderRadius:"20%", position:'relative', left:'-20px', top:'35px', zIndex:"999"}}>
            <i className='overlay-profile fa fa-camera' onClick={triggerFileSelectPopup} style={{ border:'2px solid white', borderRadius:'50%',color:'black', left:'-20px', top:'35px', fontSize:'20px', zIndex:'500', cursor:'pointer'}}></i>
    </span>*/}
        </div>
      
    )

}