import React,{useState, useEffect} from 'react';
import {Helmet} from "react-helmet-async";
import Preloader from "../Preloader";
import { ProgressBar } from '@react-pdf-viewer/core';

// Import the main component
import {Viewer } from '@react-pdf-viewer/core'; // install this library
// Plugins
//import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'; // install this library
//import { searchPlugin } from '@react-pdf-viewer/search';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
//import { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// Worker
import { Worker } from '@react-pdf-viewer/core'; // install this library
//import axios from 'axios';
//import { Auth } from "aws-amplify";
//const config = require('../../config.json');
const magazineBackground = require("../../assets/jounrnal_background.jpg")
const writingGif=require("../../assets/writing.gif");
//const magazine2023 = require("../../assets/mag2023.pdf")
const activeColour="#097969";
const passiveColour="#B6B3B2";


export const ViewMagazine1 = () => {
  // for onchange event
  //const [pdfFile, setPdfFile]=useState(null);
  const [loaded, setLoaded] = useState(true);
  const [pdf1View, setPdf1View] =useState(true);
  const [pdf2View, setPdf2View] =useState(false);
  const [pdf3View, setPdf3View] =useState(false);
  const [pdf4View, setPdf4View] =useState(false);
  const [pdf1File, setPdf1File] = useState(null);
  const [pdf2File, setPdf2File] = useState(null);
  const [pdf3File, setPdf3File] = useState(null);
  const [pdf4File, setPdf4File] = useState(null);
  const [progressValue, setProgressValue] = useState(0);
  // Create new plugin instance
  //const defaultLayoutPluginInstance = defaultLayoutPlugin();
  //const searchPluginInstance = searchPlugin();
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  useEffect(() => {
    if(!pdf1File){
      setLoaded(false);
    }
    setPdf1View(true);
    setPdf2View(false);
    setPdf3View(false);
    setPdf4View(false);
    setPdf1File("https://s3.ap-south-1.amazonaws.com/images.vjdepth.in/docs/magazine/2023/Mag20230411-1.pdf");  
    setPdf2File("https://s3.ap-south-1.amazonaws.com/images.vjdepth.in/docs/magazine/2023/Mag20230411-2.pdf");  
    setPdf3File("https://s3.ap-south-1.amazonaws.com/images.vjdepth.in/docs/magazine/2023/Mag20230411-3.pdf");  
    setPdf4File("https://s3.ap-south-1.amazonaws.com/images.vjdepth.in/docs/magazine/2023/Mag20230411-4.pdf");  

    window.scrollTo(0,0);
  }, [])


  const transform = (slot) => ({
      ...slot,
      Download: () => <></>,
      DownloadMenuItem: () => <></>,
      Open: () => <></>,
      OpenMenuItem: () => <></>,
      Print: () => <></>,
      PrintMenuItem: () => <></>,
      //EnterFullScreen: () => <></>,
      //EnterFullScreenMenuItem: () => <></>,
      SwitchTheme: () => <></>,
      SwitchThemeMenuItem: () => <></>,
  });

/*const handleFetchPDF = async(e, item) => {
    e.preventDefault();
    //console.log("fetch : ", item);
    try{
      const fetchFile=item.journalID+".pdf";
      //console.log("fetch file :", fetchFile);
      const bucketName='docs.vidyajyotijournal.com/pdfs';
      const session = await Auth.currentSession();
      const access_token=session.idToken.jwtToken;
      await axios.get(`${config.api.invokeUrl}/presigned/downloader?fileName=${fetchFile}&bucketName=${bucketName}`,{
        headers: {
          Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
     ).then(async(response)=>{
          //console.log("Response : ", response);
          setPdfFile(response.data.downloadURL);
          //setShowPDF(true); 
      }).catch((error)=>{
          console.log("Error1 : ", error);
          //setDispMsg("Error Occured - ", error.message);
      })        
    }catch(error){
        console.log("Error2 : ", error);
    }
}*/

/*const handleBack=(e)=>{
  e.preventDefault();
  setPdfFile('');
  //setShowPDF(false); 
}*/

const handlePageChange = (e) => {
  localStorage.setItem('current-page', `${e.currentPage}`);
};

const handleSwitchTheme = (theme) => {
  localStorage.setItem('current-theme', theme);
};

const handleDocumentLoad=(e)=>{
  setLoaded(true);
}

const handlePDFView=(event, pageView)=>{
  event.preventDefault();
  if(pageView==="View1"){
    setLoaded(false);
    //setPdfFile("https://s3.ap-south-1.amazonaws.com/images.vjdepth.in/docs/magazine/2023/Mag20230411-1.pdf");  
    setPdf1View(true);
    setPdf2View(false);
    setPdf3View(false);
    setPdf4View(false);
  }else if(pageView==="View2"){
    setLoaded(false);
    //setPdfFile("https://s3.ap-south-1.amazonaws.com/images.vjdepth.in/docs/magazine/2023/Mag20230411-2.pdf");  
    setPdf1View(false);
    setPdf2View(true);
    setPdf3View(false);
    setPdf4View(false);
  }else if(pageView==="View3"){
    setLoaded(false);
    //setPdfFile("https://s3.ap-south-1.amazonaws.com/images.vjdepth.in/docs/magazine/2023/Mag20230411-3.pdf");  
    setPdf1View(false);
    setPdf2View(false);
    setPdf3View(true);
    setPdf4View(false);
  }else {
    setLoaded(false);
    //setPdfFile("https://s3.ap-south-1.amazonaws.com/images.vjdepth.in/docs/magazine/2023/Mag20230411-4.pdf");  
    setPdf1View(false);
    setPdf2View(false);
    setPdf3View(false);
    setPdf4View(true);
  }
  }


/*const handleEditPDF=(e, item,action)=>{
e.preventDefault();
try{
  setPDFFormType(action);
  setPDFDetails(item);
  toggle();
}catch(error){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message:"Error Occured - " + error.message
});        
}
}*/



  return (
    <>
    <Helmet>
      <title>VJDepth - Distance Education Programme in Theology</title>
        <meta name="description" content="VJDepth - Distance Education Programme in Theology. Distance Education Programme in Theology (DEPTh) The Second Vatican Council had a dream when it wrote: “Let the laity, therefore, diligently apply themselves to a more profound knowledge of revealed truth…” (LG, 35). Sharing in the same dream Vidyajyoti has initiated the new Distance Education Programme in Theology (DEPTh) primarily for the lay faithful. It welcomes all who desire to deepen their Christian faith, and search for avenues of deeper participation in the mission of the Church." />
        <meta name="keywords" content="VJDepth - Distance Education Programme in Theology" />
        <link rel="canonical" href="/magazine/2023" />
    </Helmet>
    <div style={{ backgroundImage: 'url(' + magazineBackground + ')' , backgroundRepeat:"repeat", height:"1200px"}}>
       <Preloader show={loaded ? false : true} />
        {/*<Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>*/}

    {/*<Row style={{padding:"5px"}}> 
    {!showPDF && pdfJournalDetails.map((item,index)=><>
      <Col  md={4}>
            <Card key={index}>
                <Card.Img style={{height:'200px'}}  variant="top" src={`${config.s3.imginvokeUrl}${item.journalID}.jpeg`} />
                <Card.Body>
                <Card.Title style={{fontSize:"14px", height:'50px', textAlign:"center"}}>{item.journalName} <p style={{fontSize:"12px", height:'20px'}}><i>{item.authorName} </i></p></Card.Title>
                <Card.Text style={{fontSize:"12px", height:"130px", overflow:'hidden'}}>
                    {item.description}
                </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <small className="text-muted">{item.rating}</small>
                    <div className='d-flex justify-content-between'>
                    
                      <FontAwesomeIcon icon={faEdit} onClick={event=>{handleEditPDF(event, item,"Edit")}}/>
                      
                      <FontAwesomeIcon icon={faReadme} onClick={event=>{handleFetchPDF(event, item)}}/>
                     
                    </div>
                    <div >
                     
                    </div>
                   <div>
                     
                   </div>
                   
                </Card.Footer>
            </Card>
        
       </Col>
    </>)}
    
     </Row>*/}

      <div className="container" style={{backgroundColor:"white",paddingTop:'10px',  height:'600px',  borderRadius:25}}>
        <p style={{textAlign:"center", fontSize:"18px", backgroundColor:"#B6B3B2", borderRadius:"25px"}}>"inDepth" Laity-Theology Magazine - 2023</p>

        <div className='container d-flex justify-content-between' style={{maxWidth:"400px"}}>
      <button className="button" style={{borderRadius:25, width:"100px", backgroundColor: pdf1View? activeColour:passiveColour}} onClick={(event)=>handlePDFView(event, "View1")}> Pg 1-9</button>
      <button className="button" style={{borderRadius:25, width:"100px", backgroundColor:pdf2View? activeColour:passiveColour}} onClick={(event)=>handlePDFView(event, "View2")}> Pg 10-26</button>
      <button className="button" style={{borderRadius:25, width:"100px", backgroundColor:pdf3View? activeColour:passiveColour}} onClick={(event)=>handlePDFView(event, "View3")}> Pg 27-43</button>
      <button className="button" style={{borderRadius:25, width:"100px", backgroundColor:pdf4View? activeColour:passiveColour}} onClick={(event)=>handlePDFView(event, "View4")}> Pg 44-56</button>
     </div>
        
     {progressValue<100 ?<div  className='container justify-content-between'  style={{ width: '340px', top:"25%"}}>
              <p style={{ alignItems:'center'}}>Loading... Please Wait...</p>
                <ProgressBar progress={progressValue} />
                <img src={writingGif} alt="Writing Gif"/>
            </div>:null}


        {pdf1View &&  pdf1File && <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js">

        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>

          <Viewer fileUrl={pdf1File}  onPageChange={handlePageChange} plugins={[toolbarPluginInstance]} theme="auto"
          onSwitchTheme={handleSwitchTheme} onDocumentLoad={handleDocumentLoad}

          renderLoader={(percentages) => (
            setProgressValue(Math.round(percentages))
        )}
    
        />
      </Worker>}
      {pdf2View &&  pdf2File && <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js">

        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
          <Viewer fileUrl={pdf2File}  onPageChange={handlePageChange} plugins={[toolbarPluginInstance]} theme="auto"
          onSwitchTheme={handleSwitchTheme} onDocumentLoad={handleDocumentLoad}

          renderLoader={(percentages) => (
            setProgressValue(Math.round(percentages))

        )}
    
        />
      </Worker>}
      {pdf3View && pdf3File && <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js">

        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
          <Viewer fileUrl={pdf3File}  onPageChange={handlePageChange} plugins={[toolbarPluginInstance]} theme="auto"
          onSwitchTheme={handleSwitchTheme} onDocumentLoad={handleDocumentLoad}

          renderLoader={(percentages) => (
            setProgressValue(Math.round(percentages))
        )}
    
        />
      </Worker>}
      {pdf4View && pdf4File && <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.5.141/build/pdf.worker.min.js">

        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
          <Viewer fileUrl={pdf4File}  onPageChange={handlePageChange} plugins={[toolbarPluginInstance]} theme="auto"
          onSwitchTheme={handleSwitchTheme} onDocumentLoad={handleDocumentLoad}

          renderLoader={(percentages) => (
            setProgressValue(Math.round(percentages))
        )}
    
        />
      </Worker>}
      <div className='container d-flex justify-content-between' style={{maxWidth:"400px"}}>
      <button className="button" style={{borderRadius:25, width:"100px", backgroundColor: pdf1View? activeColour:passiveColour}} onClick={(event)=>handlePDFView(event, "View1")}> Pg 1-9</button>
      <button className="button" style={{borderRadius:25, width:"100px", backgroundColor:pdf2View? activeColour:passiveColour}} onClick={(event)=>handlePDFView(event, "View2")}> Pg 10-26</button>
      <button className="button" style={{borderRadius:25, width:"100px", backgroundColor:pdf3View? activeColour:passiveColour}} onClick={(event)=>handlePDFView(event, "View3")}> Pg 27-43</button>
      <button className="button" style={{borderRadius:25, width:"100px", backgroundColor:pdf4View? activeColour:passiveColour}} onClick={(event)=>handlePDFView(event, "View4")}> Pg 44-56</button>
     </div>

    </div>
      {/*<div
            className="rpv-core__viewer"
            style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#eeeeee',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    padding: '0.25rem',
                }}
            >
                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
            </div>
            <div
                style={{
                    flex: 1,
                    overflow: 'hidden',
                }}
            ><Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={url} plugins={[toolbarPluginInstance]} />
                </Worker>
            </div>
            </div>*/}

    </div>
    </>
  )
}

export default ViewMagazine1;