import React, { useState } from 'react';
import './bottombar.css';
//import { ProductContext } from '../../ProductProvider';

import { Link, useNavigate } from "react-router-dom";

export default function Bottombar() {
   
    const navigate = useNavigate();
   // const { loggedUser} = useContext(ProductContext);
    const [activeTab, setActiveTab] = useState('Contact');
    
    

    const handleShow = (value) => {
       
            if (value === "Contact") {
                    setActiveTab('Contact');
                    navigate('/contactus');
                } else if (value==="QuickProfile") {
                    setActiveTab('QuickProfile');
                    navigate('/');
                } else if (value==="SearchView") {
                    setActiveTab('SearchView');
                    navigate('/');
                }
            }
    
 
  
    return (
       

      
         <div className="fixed-bottom">
                <div className="content-view d-flex" style={{bottom: "0", backgroundColor:"#001822", height:"55px", padding:"8px", fontSize:"24px"}}>
                   
                    <div className="justify-content-center" style={{width:"35%", textAlign:"center"}}>
                    <Link to="#"  onClick={()=>{handleShow("QuickProfile")}}>
                               {activeTab==="QuickProfile"? <i style={{color:"white", fontSize:"30px"}} className="fa fa-user" ></i>
                               :<i className="fa fa-user" ></i>}
                            <span className="link_name"></span>
                            
                        </Link>
                     
                    </div>
                    <div className="justify-content-center" style={{width:"35%", textAlign:"center"}}>
                    <Link to="#"  onClick={()=>handleShow("SearchView")}>
                           {activeTab==="SearchView"? <i style={{color:"white", fontSize:"30px"}} className="fa fa-search"></i>
                           :<i className="fa fa-search"></i>}
                            <span className="link_name"></span>
                        </Link>
                       
                        </div>
                        <div className="justify-content-center" style={{width:"35%", textAlign:"center"}}>
                    <Link to="#" onClick={()=>handleShow("QuickView")}>
                            {activeTab==="QuickView"?<i style={{color:"white", fontSize:"30px"}} className="fa fa-home"></i>
                            :<i className="fa fa-home"></i>}
                            <span className="link_name"></span>
                        </Link>
                        </div>
                    <div className="justify-content-center" style={{width:"35%", textAlign:"center"}}>
                    <Link to="#" onClick={()=>handleShow("Transaction")}>
                            {activeTab==="Transaction"?<i style={{color:"white", fontSize:"30px"}} className="fa fa-book"></i>
                            :<i className="fa fa-book"></i>}
                            <span className="link_name"></span>
                        </Link>
                        </div>
                </div>
              
            
                
            </div>
           
        )
    
}
