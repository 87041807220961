
import React, { useEffect, useState } from "react";
import { Col, Row, Card, Form, Button } from 'react-bootstrap';
import {Input, Alert} from 'reactstrap';
import axios from "axios";
//import ProfileImage from "./ProfileImage";
import IDProof from "./IDProof";
import CertificateProof from "./CertificateProof";
import Preloader from "../Preloader";
import { coursesOffered, phoneCode } from "../../ProData";
import AcademicInfo from "./AcademicInfo";
import {Helmet} from "react-helmet-async";
import PhotoProof from "./PhotoProof";

const config = require('../../config.json');
const defaultAvatar = require("../../assets/defaultAvatar.jpg")
const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;


export  default function RegistrationNew() {
  
  const [form, setForm]=useState({title:'Mr',firstName:'', middleName:'', lastName:'', dateOfBirth:'', gender:'Male', maritialStatus:'Married',
  occupation:'', religion:'Christian', denomination:'', diocese:'', areaCode:'+91', mobileNumber:'', emailID:'', landlineNumber:'', addressStreet:'', addressState:'Delhi',
  addressPincode:'', nationality:'', motherTongue:'', englishProficiency:'Excellent', degreeDetails:[]});
  const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});
  const [profileImage, setProfileImage] = useState(defaultAvatar);
  const [imageFile, setImageFile] = useState('');
  const [idProofFile, setIDProofFile]= useState('');
  const [certProofFile, setCertProofFile]=useState('');
 // const [emailValidated, setEmailValidated]=useState(false);
  const [loaded, setLoaded] = useState(true);
  const [emailCodeReceived, setEmailCodeReceived] = useState('');
  const [emailCodeEntered, setEmailCodeEntered]= useState('')
  const [optedCourse, setOptedCourse]=useState({courseName:'',courseCategory:''});
  const [coursesAvailable, setCoursesAvailable]= useState([]);
  const [courseOptionAvailable, setCourseOptionAvailable]= useState([]);
  const [selectedCourse, setSelectedCourse]=useState({});
  const [isCourseShortlisted, setIsCoursseShortlisted]= useState(false);
  const [isEmailValidated, setIsEmailValidated]=useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [applicationProcessed, setApplicationProcessed]=useState(false);
  const [app, setApp]=useState({appNo:'', appMsg:''});

  useEffect(()=>{
    initializeValues();
    //console.log("Courses Option : ", sortedArray);
  },[])

  const initializeValues=()=>{
    var uniqueArray = removeDuplicates("courseName");
    var sortedcourseArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
    setCoursesAvailable(sortedcourseArray);
    //console.log("Courses Name : ", sortedArray);
    uniqueArray = removeDuplicates("courseOption");
    var sortedOptionArray = uniqueArray.sort((a, b) => {return a.cat_id - b.cat_id;});
    setCourseOptionAvailable(sortedOptionArray);
    setOptedCourse({...optedCourse,
      courseName:sortedcourseArray[0].courseName,
      courseOption:sortedOptionArray[0].courseOption
    });
  }

  const removeDuplicates=(tprop)=> {
    var newArray = [];
    var lookupObject  = {};
    for(var i in coursesOffered) {
       lookupObject[coursesOffered[i][tprop]] = coursesOffered[i];
    }

    for(i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
     return newArray;
}

  const generateApplicationNo=()=>{
      const current_datetime = new Date();
      const fName=form.firstName.trim().replace(/\s/g, "");
      //const tusername=memberInfo.name.trim().replace(/\s/g, "");
      const tfName= fName.substring(0,2).toUpperCase();
      const lName=form.lastName.trim().replace(/\s/g, "");
      //const tusername=memberInfo.name.trim().replace(/\s/g, "");
      const tlName= lName.substring(0,2).toUpperCase();
      var tempUserId="";
      const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
      var date_month = (current_datetime.getMonth() + 1).toString();
      var month_value="";
      date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
      var date_date = (current_datetime.getDate().toString());
      var date_value="";
      date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
      var date_hours = (current_datetime.getHours().toString());
      var hours_value="";
      date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
      var date_minutes = (current_datetime.getMinutes().toString());
      var minutes_value="";
      date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
      const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value,tfName, tlName);
      //setUserId(accountNo);
      //setMemberInfo({...memberInfo, userId:accountNo});
      return accountNo;
      //console.log("acc : ", accountNo);
    
  }

  const generateDate=()=>{
    const current_datetime = new Date();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,"-",month_value,"-", date_value);
    //setUserId(accountNo);
    //setMemberInfo({...memberInfo, userId:accountNo});
    return accountNo;
    //console.log("acc : ", accountNo);
  
}

const validateFields=()=>{
  const {title,firstName, dateOfBirth, gender, maritialStatus,
    occupation, religion, mobileNumber, emailID, addressStreet, addressState,
    addressPincode, nationality, motherTongue, englishProficiency}=form;
    var returnValue="passed";
    if(title===""){
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Title cannot be Empty"
    }); 
    returnValue="failed";
  }else if(firstName===""){
    setAlertDetails({
      ...alertDetails,
      style:"warning",
      open:true,
      message: "Name cannot be Empty"
  }); 
  returnValue="failed";
}else if(dateOfBirth===""){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "Date of Birth cannot be Empty"
}); 
returnValue="failed";
}else if(gender===""){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "Gender cannot be Empty"
}); 
returnValue="failed";
}else if(maritialStatus===""){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "Maritial Status cannot be Empty"
}); 
returnValue="failed";
}else if(occupation===""){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "Occupation cannot be Empty"
}); 
returnValue="failed";
}else if(religion===""){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "Religion cannot be Empty"
}); 
returnValue="failed";
}else if(mobileNumber===""){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "Mobile Number cannot be Empty"
}); 
returnValue="failed";
}else if(!emailID.match(validRegex)){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "Email address is not in right format!"
}); 
returnValue="failed";
}else if(emailID===""){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "Email Address cannot be Empty"
}); 
returnValue="failed";
}else if(addressStreet==="" || addressPincode==="" || addressState===""){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "Address cannot be Empty"
}); 
returnValue="failed";
}else if(nationality===""){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "Nationality cannot be Empty"
}); 
returnValue="failed";
}else if(motherTongue===""){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "Mother Tongue cannot be Empty"
}); 
returnValue="failed";
}else if(englishProficiency===""){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "English Proficiency cannot be Empty"
}); 
returnValue="failed";
}else if(imageFile===""){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "Attach your Photo. Photo is must for registration!"
}); 
returnValue="failed";
}else if(idProofFile===""){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "Attach one Govt approved photo ID proof! Its must."
}); 
returnValue="failed";
}else if(certProofFile===""){
  setAlertDetails({
    ...alertDetails,
    style:"warning",
    open:true,
    message: "Attach UG/PG certificate. It's mandatory for registration."
}); 
returnValue="failed";
}else  {
  returnValue="passed";

}
window.scrollTo(0,0);
return returnValue;
}

const handleEmailValidation=async(event)=>{
  event.preventDefault();
  try{
    const validationResult=validateFields();
    if(validationResult==="passed"){
    setLoaded(false);
        //const clientID="VJDEPTH";
        await axios.get(`${config.api.invokeURL}/form/registration/emailvalidation?emailID=${form.emailID}`,{
            headers: {
             // Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
          //console.log(response);
          if(response.status===200){
            setEmailCodeReceived(response.data);
            //setEmailValidated(true);
            setIsEmailValidated(true);
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: "One Time password has been sent to your email addresss"
          });  
          window.scrollTo(0,0);
          }else{
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Duplicate Records Found!"
          });  
          window.scrollTo(0,0);
          }
            setLoaded(true);
        }).catch((error)=>{
            //console.log(error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            window.scrollTo(0,0);
            setLoaded(true);
        })
      }
  }catch(error){
    setLoaded(true);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message: "Error Occured : " + error.message
    });                 
    window.scrollTo(0,0);

  }
}

  const handleSubmit=async(event)=>{
    event.preventDefault();
    try{
      alertClose();
    //  console.log("value : ", emailCodeEntered, emailCodeReceived);
      const temailcodeentered=emailCodeEntered.toString();
      const temailcodereceived=emailCodeReceived.toString();
      if(temailcodeentered===temailcodereceived){
        setLoaded(false);
      const {title,firstName, middleName, lastName, dateOfBirth, gender, maritialStatus,
      occupation, religion, denomination, diocese, areaCode, mobileNumber, emailID, landlineNumber, addressStreet, addressState,
      addressPincode, nationality, motherTongue, englishProficiency, degreeDetails}=form;
      const clientID = "VJDEPTH";
      const applicationno= await generateApplicationNo();
      const applicationDate=await generateDate();
      const applicationStatus="Pending Approval";
      const avatar= applicationno+".jpeg";
      const idproofFile=applicationno+"_idproof.jpeg"; 
      const certproofFile=applicationno+"_certproof.jpeg"; 
      const tcourseStatus="Pending Approval";
      const tcoursePayment="Pending";
      const tapplicantName=firstName+ " "+middleName+ " "+lastName;
      const params={
        clientID:clientID, applicationNo: applicationno, applicantName:tapplicantName, applicationDate: applicationDate, applicationStatus:applicationStatus,
        title:title,firstName:firstName, middleName:middleName, lastName:lastName, dateOfBirth:dateOfBirth, gender:gender, maritialStatus:maritialStatus,
        occupation:occupation, religion:religion, denomination:denomination, diocese:diocese, areaCode:areaCode, mobileNumber:mobileNumber, emailID:emailID, landlineNumber:landlineNumber, addressStreet:addressStreet, addressState:addressState,
        addressPincode:addressPincode, nationality:nationality, motherTongue:motherTongue, englishProficiency:englishProficiency, selectedCourse:selectedCourse, degreeDetails:degreeDetails, avatar:avatar, idproofFile:idproofFile, 
        certproofFile:certproofFile, courseStatus:tcourseStatus, coursePayment:tcoursePayment};
      //console.log("Params : ", params);
      await axios.post(`${config.api.invokeURL}/form/registration/${applicationno}`, params,{
        headers: {
        //  Authorization: access_token,
          'x-api-key':config.api.key
        }})
        .then(async(response)=>{
       //   console.log("response : ", response); 
          await postOnS3(applicationno, imageFile,"Profile");
          await postOnS3(applicationno, idProofFile,"IDProof");
          await postOnS3(applicationno, certProofFile,"CERTProof");
          setApplicationProcessed(true);
          setApp({...app, appNo:applicationno, appMsg:"Congratulations and Thank you for showing interest in our learning program!!! Your Application Registration is successful. Your application will be reviewed throughly and further updates will be shared via Email. You can also get the latest updates from our portal."})
          setAlertDetails({
            ...alertDetails,
            style:"success",
            open:true,
            message: applicationno + " - Successfully Registered."
        }); 
        window.scrollTo(0,0);
        resetValues();
        setLoaded(true);
        }).catch((error)=>{
       //   console.log("error : ", error);
            setAlertDetails({
              ...alertDetails,
              style:"warning",
              open:true,
              message: "Error Occured : " + error.message
          }); 
          window.scrollTo(0,0);
          setLoaded(true);
        })
      }else{
        setAlertDetails({
          ...alertDetails,
          style:"warning",
          open:true,
          message: "Incorrect OTP Entered! Try Again!!!"
      }); 
      window.scrollTo(0,0);
      setLoaded(true);
      }
      }catch(error){
  //      console.log("error : ", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    window.scrollTo(0,0);
    setLoaded(true);
    }
  }

  const handleNewForm=()=>{
    resetValues();
    setApp({...app, appNo:'', appMsg:''});
    setApplicationProcessed(false);
    alertClose();
  }

  const postOnS3 = async(applicationno,timageFile,inputType)=>{
    try{
        //  console.log("NewImage : ", newImage);
        var tKeyName="";
        var newFileName="";
        if(inputType==="Profile"){
          tKeyName="avatar/"+applicationno;   
          newFileName=applicationno+".jpeg";
        }else if (inputType==="IDProof"){
          tKeyName="avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_idproof.jpeg"; 
        }else if (inputType==="CERTProof"){
          tKeyName="avatar/"+applicationno+"/docs";  
          newFileName=applicationno+"_certproof.jpeg"; 
        }   
      
      //console.log("newfilename :", newFileName);
     // const newImage="";
      var file = timageFile;
      var blob = file.slice(0, file.size, 'image/jpeg'); 
      const newFile = new File([blob], newFileName, {type: 'image/jpeg'});
     // console.log("New file : ", newFile);
     // const session = await Auth.currentSession();
     // const access_token=session.idToken.jwtToken;
      const bucketName=config.s3.bucketName + tKeyName;
     // await axios.get(`${config.api.invokeUrl}/profile_avatar/presigned/uploader?fileName=${newFileName}&bucketName=${bucketName}`,{
     // await axios.get(`${config.api.invokeURL}/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}&keyName=${newKeyName}`,{
     //await axios.get(`${config.api.invokeURL}/dashboard/images/gallery/newgallery?fileName=${newFileName}&bucketName=${bucketName}`,{
      await axios.get(`${config.api.invokeURL}/form/registration/images?fileName=${newFileName}&bucketName=${bucketName}`,{

           headers:{
       //        Authorization: access_token,
               'x-api-key': config.api.key
           }
       }).then(async(response)=>{
          // console.log("Response : ", response);
          const res=await fetch(response.data.uploadURL, { method: 'PUT',
           headers:{
               "Content-Type":"image/jpeg"
             },body: newFile});
           //  console.log("Res : ", res);
             if(res.status===200){
            //  setNewProfileImage(newFileName);
              //  fetchGalleryImages();
                // setProfileImage(newFileName);
                 //var timestamp = new Date().getTime();     
                // var tprofile = document.getElementById("profile");
                 //var imgsrc="";
                 //imgsrc=imgsrc.concat(config.s3.imageURL,newFileName,"?t=",timestamp);     
                 //tprofile.src=`${config.s3.imageURL}${newFileName}`+"?t="+timestamp;
                // tprofile.src=imgsrc;
                //tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                 //console.log("tprofle : ", tprofile.src);
             }
        }).catch((error)=>{
            //console.log("error1:", error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
         // setDispMsg(error.message);
         // hideLoader();
       })
    
    }catch(error){
      //console.log("error1:", error);
      setAlertDetails({
        ...alertDetails,
        style:"warning",
        open:true,
        message:"Error Occured - " + error.message
    }); 
    }
  
  }

const resetValues=()=>{
  setForm({...form,title:'Mr',firstName:'', middleName:'', lastName:'', dateOfBirth:'', gender:'Male', maritialStatus:'Married',
  occupation:'', religion:'Christian', denomination:'', diocese:'', areaCode:'+91', mobileNumber:'', emailID:'', landlineNumber:'', addressStreet:'', addressState:'Delhi',
  addressPincode:'', nationality:'', motherTongue:'', englishProficiency:'Excellent', degreeDetails:[]});
  setImageFile('');
  setIDProofFile('');
  setCertProofFile('');
  setEmailCodeEntered('');
  setEmailCodeReceived('');
  //setEmailValidated(false);
  setIsCoursseShortlisted(false);
  setIsEmailValidated(false);
  setIsFormSubmitted(false);
  initializeValues();
}

  const alertClose=()=>{
    setAlertDetails({
        ...alertDetails,
        style:"",
        open:false,
        message:""
    });
}

const handleCourseSelection =(event)=>{
  event.preventDefault();
 // console.log("Opted Course : ", optedCourse);

  for(const i in coursesOffered){
  //  console.log("selected Course : ", coursesOffered[i] );
    if(coursesOffered[i].courseName===optedCourse.courseName && coursesOffered[i].courseOption===optedCourse.courseOption){
    //  console.log("selected Course : ", coursesOffered[i] );
      setSelectedCourse(coursesOffered[i]);
      setIsCoursseShortlisted(true);
    }
  }
}

  return (
  <>
      <Helmet>
      <title>Online Registration Form - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Online Registration Form - VJDepth - Distance Education Programme in Theology" />
        <meta name="keywords" content="Registration - VJDepth - Distance Education Programme in Theology" />
        <link rel="canonical" href="/registration" />
    </Helmet>

    <Preloader show={loaded ? false : true} />
  {!isCourseShortlisted && !applicationProcessed?
       <Card border="light" className=" bg-white shadow-sm mb-4">
       <Card.Body>     
         <h5 className="mb-4">Registration - Select the Course</h5>
         <Form>
         <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
         {alertDetails.message}
         </Alert>
         <Row>
         <Form.Group id="otp">
                 <Form.Label>Select the Course</Form.Label>
                 <Input type="select" name="courseName"
                                  value={optedCourse.courseName} 
                                  onChange={(event)=>{setOptedCourse({...optedCourse, courseName:(event.target.value)})}}
                                >
                                  {coursesAvailable.map(item => {
                                return (<option key={item.courseName} value={item.courseName}>{item.courseName}</option>);
                              })}
                  </Input>
               </Form.Group>
         </Row>
         <Row>
         <Form.Group id="otp">
                 <Form.Label>Select the Preference Mode</Form.Label>
                 <Input type="select" name="courseOption"
                                  value={optedCourse.courseOption} 
                                  onChange={(event)=>{setOptedCourse({...optedCourse, courseOption:(event.target.value)})}}
                                >
                                  {courseOptionAvailable.map(item => {
                                return (<option key={item.courseOption} value={item.courseOption}>{item.courseOption}</option>);
                              })}
                  </Input>
               </Form.Group>
         </Row>
         <div className="mt-3 text-center">
             <Button variant="primary" type="submit" onClick={(event)=>{handleCourseSelection(event)}}>Select Course</Button>
           </div>
         </Form>
         </Card.Body>
      </Card>
  :null}
  {!isEmailValidated && isCourseShortlisted ?  
  <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body className="container">     
        <h5 className="mb-4">Registration - Application Form</h5>
        <Form>
        <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        {/*<ProfileImage fileName="test" setProfileImage={setProfileImage} profileImage={profileImage} alertDetails={alertDetails} setAlertDetails={setAlertDetails} setImageFile={setImageFile}/>*/}
        
        <Row>
            <Col md={2} className="mb-3">
              <Form.Group id="title">
                <Form.Label>Title</Form.Label>
                <Form.Select value={form.title} 
                onChange={(event)=>{setForm({...form, title:event.target.value})}}>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Sr">Sr</option>
                  <option value="Br">Br</option>
                  <option value="Fr">Fr</option>
                  <option value="Other">other</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={4} className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control required type="text" placeholder="Enter your First name" 
                value={form.firstName} onChange={(event)=>{setForm({...form, firstName:event.target.value})}}/>
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="middleName">
                <Form.Label>Middle Name</Form.Label>
                <Form.Control required type="text" placeholder="Also your Middle name" 
                value={form.middleName} onChange={(event)=>{setForm({...form, middleName:event.target.value})}}/>
              </Form.Group>
            </Col>
            <Col md={3} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control required type="text" placeholder="Also your last name" 
                value={form.lastName} onChange={(event)=>{setForm({...form, lastName:event.target.value})}}/>
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="birthday">
                <Form.Label>Date Of Birth</Form.Label>
                <Input type="date" 
                value={form.dateOfBirth} onChange={(event)=>{setForm({...form, dateOfBirth:event.target.value})}}/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="gender">
                <Form.Label>Gender</Form.Label>
                <Form.Select value={form.gender} onChange={(event)=>{setForm({...form, gender:event.target.value})}}>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="maritalstatus">
                <Form.Label>Marital Status</Form.Label>
                <Form.Select value={form.maritialStatus} onChange={(event)=>{setForm({...form, maritialStatus:event.target.value})}}>
                  <option value="Married">Married</option>
                  <option value="Unmarried">Unmarried</option>
                  
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="occupation">
                <Form.Label>Occupation</Form.Label>
                <Form.Control required type="text" placeholder="Your Occupation" 
                value={form.occupation} onChange={(event)=>{setForm({...form, occupation:event.target.value})}}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="religion">
                <Form.Label>Religion</Form.Label>
                <Form.Select value={form.religion} onChange={(event)=>{setForm({...form, religion:event.target.value})}}>
                  <option value="Christian">Christian</option>
                  <option value="Hindu">Hindu</option>
                  <option value="Muslim">Muslim</option>
                  <option value="Other">Other</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="denomination">
                <Form.Label>Denomination</Form.Label>
                <Form.Control required type="text" placeholder="Your Denomination ex:Catholic" 
                value={form.denomination} onChange={(event)=>{setForm({...form, denomination:event.target.value})}}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
          <Col sm={6} className="mb-3">
              <Form.Group id="diocese">
                <Form.Label>Diocese</Form.Label>
                <Form.Control required type="text" placeholder="Diocese" 
                value={form.diocese} onChange={(event)=>{setForm({...form, diocese:event.target.value})}}/>
              </Form.Group>
            </Col>
            
            <Col sm={6} className="mb-3">
              <Form.Group id="areanumber">
                <Form.Label>Mobile Number</Form.Label>
                <Row>
                <Col sm={2}>
            <Input type="select" name="courseName"
                                  value={form.areaCode} 
                                  onChange={(event)=>{setForm({...form, areaCode:(event.target.value)})}}
                                >
                                  {phoneCode.map(item => {
                                return (<option key={item.Code} value={item.Code}>{item.Code}</option>);
                              })}
                  </Input>
            </Col>
            <Col sm={10}>
            <Form.Control required type="number" placeholder="Enter your mobile number" 
                value={form.mobileNumber} onChange={(event)=>{setForm({...form, mobileNumber:event.target.value})}}/>
            </Col>
                </Row>               
              </Form.Group>
            </Col>
            
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="emal">
                <Form.Label>Email</Form.Label>
                <Form.Control required type="email" placeholder="name@company.com" 
                value={form.emailID} onChange={(event)=>{setForm({...form, emailID:event.target.value})}}/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="phone">
                <Form.Label>Landline Number</Form.Label>
                <Form.Control required type="number" placeholder="+12-345 678 910" 
                value={form.landlineNumber} onChange={(event)=>{setForm({...form, landlineNumber:event.target.value})}}/>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6} className="mb-3">
              <Form.Group id="address">
                <Form.Label>Address</Form.Label>
                <Form.Control required type="text" placeholder="Enter your residential address" 
                value={form.addressStreet} onChange={(event)=>{setForm({...form, addressStreet:event.target.value})}}/>
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group className="mb-2">
                <Form.Label>State</Form.Label>
                <Form.Control required id="state" placeholder="Enter your State"
                value={form.addressState} onChange={(event)=>{setForm({...form, addressState:event.target.value})}} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group id="zip">
                <Form.Label>ZIP</Form.Label>
                <Form.Control required type="tel" placeholder="ZIP" 
                value={form.addressPincode} onChange={(event)=>{setForm({...form, addressPincode:event.target.value})}}/>
              </Form.Group>
            </Col>
            <Col sm={6} className="mb-3">
              <Form.Group id="nationality">
                <Form.Label>Nationality</Form.Label>
                <Form.Control required type="text" placeholder="Enter your Nationality" 
                value={form.nationality} onChange={(event)=>{setForm({...form, nationality:event.target.value})}}/>
              </Form.Group>
            </Col>
            
          </Row>
          <Row>
          <Col md={6} className="mb-3">
              <Form.Group id="denomination">
                <Form.Label>Mother Tongue</Form.Label>
                <Form.Control required type="text" placeholder="Your Mother Tongue" 
                value={form.motherTongue} onChange={(event)=>{setForm({...form, motherTongue:event.target.value})}}/>
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="religion">
                <Form.Label>English Proficiency</Form.Label>
                <Form.Select value={form.englishProficiency} onChange={(event)=>{setForm({...form, englishProficiency:event.target.value})}}>
                  <option value="Excellent">Excellent</option>
                  <option value="Good">Good</option>
                  <option value="Average">Average</option>
                  <option value="Poor">Poor</option>
                  <option value="Dont know English">Dont know English</option>
                </Form.Select>
              </Form.Group>
            </Col>
            
          </Row>
          <Row>
            <AcademicInfo form={form} setForm={setForm} alertDetails={alertDetails} setAlertDetails={setAlertDetails}/>
          </Row>
          <Row className="p-4">
             <Col md={4}>
            <Card className="h-100">
            <PhotoProof fileName="test" imageFile={imageFile} setProfileImage={setProfileImage} profileImage={profileImage} alertDetails={alertDetails} setAlertDetails={setAlertDetails} setImageFile={setImageFile}/>
            </Card>
            </Col>

            <Col md={4}>
            <Card className="h-100">
            <IDProof fileName="test"  alertDetails={alertDetails} setAlertDetails={setAlertDetails} setIDProofFile={setIDProofFile}/>
            </Card>
            </Col>
            <Col md={4}>
            <Card className="h-100">
            <CertificateProof fileName="test" alertDetails={alertDetails} setAlertDetails={setAlertDetails} setCertProofFile={setCertProofFile}/>
            </Card>
            </Col>
          </Row>
          
          <div className="mt-3 text-center">
            <Button variant="primary" type="submit" onClick={(event)=>{handleEmailValidation(event)}}>Submit</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>:null}
    {!isFormSubmitted && isEmailValidated && isCourseShortlisted ? 
    <Card border="light" className="container  shadow-sm mb-4 d-flex mx-auto">
      <Card.Body className="text-center justify-content-center mx-auto">     
        <h5 className="mb-4">Registration - Validation</h5>
        <Form className="text-center justify-content-center ">
        <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        <div className="m-3 " >
           <Row className="justify-content-center text-center">
        <Form.Group id="otp">
                <Form.Label>Enter the One Time Password</Form.Label>
                <Form.Control required type="text" placeholder="Enter OTP" 
                value={emailCodeEntered} onChange={(event)=>{setEmailCodeEntered(event.target.value)}}/>
              </Form.Group>
        </Row>
        <div className="mt-3 text-center">
            <Button className="m-3" variant="primary" type="submit" onClick={(event)=>{handleSubmit(event)}}>Validate OTP</Button>
            <Button variant="primary" type="submit" onClick={(event)=>{handleEmailValidation(event)}}>Resend OTP</Button>
          </div>
        </div>
        </Form>
        </Card.Body>
     </Card>:null}

     {!applicationProcessed  ? <Card border="light" className=" container bg-white shadow-sm mb-4">
      <Card.Body>     
        <h5 className="mb-4">Registration - Final Status</h5>
        <Form>
        <Alert color={alertDetails.style} fade={true} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
        </Alert>
        <Row>
        <h3 className="mb-4">Registration No - {app.appNo}</h3>
        </Row>
        <Row>
          <p>{app.appMsg}</p>
        </Row>
        <div className="mt-3 text-center">
            <Button variant="primary" type="submit" onClick={(event)=>{handleNewForm(event)}}> New Form</Button>

          </div>

        </Form>
        </Card.Body>
     </Card>:null}


    </>
  )
}
