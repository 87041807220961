import React, { useState } from 'react';
import axios from 'axios';
import {Row, Col, Card, Button, Alert} from 'reactstrap';
import Preloader from "../Preloader";
import {Helmet} from "react-helmet-async";

const config = require('../../config.json');

export default function RegStatusLogin() {
  const [applicationNo, setApplicationNo]= useState('');
  const [dateOfBirth, setDateOfBirth]=useState('');
  const [loaded, setLoaded] = useState(true);
  const [statusMsg, setStatusMsg]=useState('');
  const [alertDetails, setAlertDetails] = useState({style:"", open:false, message:""});

  const handleStatusCheck=async()=>{
    try{
        setLoaded(false);
        const clientID="VJDEPTH";
        await axios.get(`${config.api.invokeURL}/form/registration?clientID=${clientID}&applicationNo=${applicationNo}&dateOfBirth=${dateOfBirth}`,{
            headers: {
             // Authorization: access_token,
              'x-api-key': config.api.key
            }}, 
        ).then((response)=>{
         // console.log(response);
          setStatusMsg(response.data);
          if(response.status===200){
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: "Current Application Status : " + response.data
          });  

          }else{
            setAlertDetails({
              ...alertDetails,
              style:"success",
              open:true,
              message: response.dat
          });  

          }
            //console.log(response);
            setLoaded(true);
        }).catch((error)=>{
            //console.log(error);
            setAlertDetails({
                ...alertDetails,
                style:"warning",
                open:true,
                message:"Error Occured - " + error.message
            }); 
            setLoaded(true);
            //setDispMsg(error.message);
        })
    
    }catch(error){
        setAlertDetails({
            ...alertDetails,
            style:"warning",
            open:true,
            message:"Error Occured - " + error.message
        }); 
        setLoaded(true);
    }
}

const alertClose=()=>{
  setAlertDetails({
      ...alertDetails,
      style:"",
      open:false,
      message:""
  });
}

  return (
    <>
          <Helmet>
      <title>Registration Status - VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="Check Registration Status - VJDepth - Distance Education Programme in Theology" />
        <meta name="keywords" content="Check Registraion Status - VJDepth - Distance Education Programme in Theology" />
        <link rel="canonical" href="/registration-status" />

    </Helmet>

            <Preloader show={loaded ? false : true} />
            <Alert color={alertDetails.style} isOpen={alertDetails.open} toggle={alertClose} >
        {alertDetails.message}
  </Alert>

    <div className='container' style={{display:'flex', justifyContent:'center', padding:'50px'}}>
<Row>
    <Card  >
    <div >
                <div className="info-box mb-3" >
                  
                  <h3 className='text-center text-primary'>Registration Status</h3>
                  <div className="row">
                  <h5>Status: {statusMsg}</h5>
                <Col className='mt-3' xs={12} lg={6}>
                  Application Number:
                  <input type="text" name="applicationNo" className="form-control" id="applicationNo" placeholder="Application No" required
                  value={applicationNo} onChange={(event)=>{setApplicationNo(event.target.value)}}/>
                </Col>
                <Col className='mt-3'  xs={12} lg={6}>
                  Date of Birth:
                  <input type="date" className="form-control" name="dob" id="dob" placeholder="Date of Birth" required
                  value={dateOfBirth} onChange={(event)=>{setDateOfBirth(event.target.value)}}/>
                </Col>
              </div> </div>
              <div className="mt-3 text-center">
            <Button className='bg-primary' type="submit" onClick={(event)=>{handleStatusCheck(event)}} >Refresh</Button>
          </div>
              </div>
    </Card>
</Row>
    

    </div>
    </>
  )
}
