import React, { Component } from 'react'
import {Helmet} from 'react-helmet-async';
import ourapp from '../assets/img/OurApp.jpg';

export default class ContactUsSec extends Component {
  render() {
    return (
      <div>
        <Helmet>
        <title>Contact Us - VJDepth - Distance Education Programme in Theology</title>
        <meta name="description" content="Contact Us - VJDepth - Distance Education Programme in Theology. Our address, contact details and regional center details are available." />
        <meta name="keywords" content="Contact Us - VJDepth - Distance Education Programme in Theology" />
		    <link rel="canonical" href="/contactus" />

    </Helmet>
          <section id="contact" className="contact" style={{paddingTop:'40px'}}>
      <div className="container">

        <div className="section-title">
          <h1 className='text-center text-primary pt-4'>Contact Us</h1>
          <p></p>
        </div>
        <div className="row mt-5">
        <div className="col-md-6">
        <iframe  title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1750.2880920202529!2d77.22049605808131!3d28.672406095600522!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfda0e1b6e4eb%3A0x32051ba490c17c76!2sVidyajyoti%20College%20of%20Theology!5e0!3m2!1sen!2sus!4v1652092058486!5m2!1sen!2sus" width="100%" height="450" frameBorder="0" style={{border:'0', height:'330px'}} allowFullScreen=""></iframe>
          </div>
          <div className="col-md-6">
                <div className="info-box" >
           
                  <img src={ourapp} alt='' style={{height:'280px'}}/>
                </div>
                <br/>
                
              </div>
              </div>
      </div>
      <div className="container">
        <div className="row mt-5">

          <div className="col-lg-4">

            <div className="row g-0">
              <div className="col-md-12">
                <div className="info-box" >
                  <i className="fa fa-map-marker"></i>
                  <h3 className='text-center text-primary'>Our Address</h3>
                  <h5>Vidyajyoti College Depth Office</h5>
                  <p>4a Raj Niwas <br/>
                  Ludlow Castle, Civil Lines, <br/>
                  New Delhi, Delhi 110054</p>
                  <br/>
                </div>
                <br/>
                
              </div>
              
              <div className="col-md-6">
                <div className="info-box mt-4" style={{height:'200px'}}>
                  <i className="fa fa-phone"></i>
                  <h3 className='text-center text-primary'>Call Us</h3>
                  <p>+91-92052 10426</p>
                  
                </div>
              </div>
              
              <div className="col-md-6">
                <div className="info-box mt-4" style={{height:'200px'}}>
                  <i className="fa fa-envelope"></i>
                  <h3 className='text-center text-primary'>Email Us</h3>
                  <p >director@vjdepth.in</p>
                </div>
              </div>
              
              <div className="col-md-12">
                <div className="info-box" >
           
                  <img src={ourapp} alt='' style={{width:'350px'}}/>
                </div>
                <br/>
                
              </div>
            </div>
           
          </div>

          <div className="col-lg-8">
            <form action="forms/contact.php" method="post" role="form" className="php-email-form">
            <h3 className='text-center text-primary'>Regional Centers</h3>
             {/*} <div className="row">
                <div className="col form-group mt-3">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div className="col form-group mt-3">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
              </div>
              <div className="form-group mt-3">
                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
              </div>
              <div className="form-group mt-3">
                <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
              </div>
    <div className="text-center"><button type="submit">Send Message</button></div> */}
 <div className="row g-0">
     <div className="col-md-6">
                <div className="info-box mt-4" style={{height:'200px'}}>
                
                  <h3 className='text-center text-primary'>Chennai - Arul Kadal</h3>
                  <p>136, San Thome High Road, Chennai - 600028</p>
                  <p><i className="fa fa-phone"></i> 044-24938389 / 24612268</p>
                </div>
              </div>
              
              <div className="col-md-6">
                <div className="info-box mt-4" style={{height:'200px'}}>
                  
                  <h3 className='text-center text-primary'>Mumbai - St Peter's Church</h3>
                  <p >65, Ramdas Nayak Marg, 65, Hill Rd, Bandra West, Mumbai, Maharashtra 400050 </p>
                <p><i className="fa fa-phone"></i> +912226430386</p>
                </div>
              </div>
 </div>
 <div className="row g-0">
     <div className="col-md-6">
                <div className="info-box mt-4" style={{height:'200px'}}>
                
                  <h3 className='text-center text-primary'>Kolkatta - PrabhuJisurGirja</h3>
                  <p>76 Rafi Ahmed Kidwai Road Kolkata – 700016</p>
                  <p><i className="fa fa-phone"></i> 08697126461</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box mt-4" style={{height:'200px'}}>
                  
                  <h3 className='text-center text-primary'>Ranchi -  Tarunodaya</h3>
                  <p >Arsande, P.O. Boreya, Ranchi - 835240</p>
                <p><i className="fa fa-phone"></i> 0651-2450304 / 3204513</p>
                </div>
              </div>
 </div>
 <div className="row g-0">
     <div className="col-md-6">
                <div className="info-box mt-4" style={{height:'200px'}}>
                
                  <h3 className='text-center text-primary'>Pune - St. Xavier Church</h3>
                  <p>St. Vincent Street, Pune - 411001</p>
                  <p><i className="fa fa-phone"></i> 8888224464</p>
                </div>
              </div>
              
              <div className="col-md-6">
                <div className="info-box mt-4" style={{height:'200px'}}>
                  
                  <h3 className='text-center text-primary'>Goa - Jesuit's House</h3>
                  <p >Dr RS Rd, Opposite Municipal Garden, Altinho Panaji, Goa - 403521</p>
                <p><i className="fa fa-phone"></i> 9871588406</p>
                </div>
              </div>
 </div>
 <div className="row g-0">
                <div className="info-box mt-4" style={{height:'200px'}}>
                  <h3 className='text-center text-primary'>Malaysia - Archdiocesan Pastoral Centre (APC)</h3>
                  <p>5, Jalan Robertson, 50150 Kuala Lumpur, Malaysia.</p>
                  <p><i className="fa fa-phone"></i> +60320266480</p>
                </div>
              
              {/*<div className="col-md-6">
                <div className="info-box mt-4" style={{height:'200px'}}>
                  
                  <h3 className='text-center text-primary'>Goa</h3>
                  <p >Dr RS Rd, Opposite Municipal Garden, Altinho Panaji, Goa - 403521</p>
                <p></p>
                </div>
  </div>*/}
 </div>

    
            </form>
          </div>

        </div>
        
      </div>
</section>

      </div>
    )
  }
}
