import React , { useState, useEffect }  from 'react';
import InfoDesk from './InfoDesk';
import AboutSec from './AboutSec';
import CoursesDetailsView from './CoursesDetailsView';
import ContactUsSec from './ContactUsSec';
import GallerySec from './GallerySec';
import Preloader from './Preloader';
import {Helmet} from "react-helmet-async";
import MagazineSection from './magazine/MagazineSection';

export default function Home() {
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 2000);
    return () => clearTimeout(timer);
  }, []);


  return (
    <div> 
    <Helmet>
      <title>VJDepth - Distance Education Programme in Theology</title>
      <meta name="description" content="VJDepth - Distance Education Programme in Theology. Distance Education Programme in Theology (DEPTh) The Second Vatican Council had a dream when it wrote: “Let the laity, therefore, diligently apply themselves to a more profound knowledge of revealed truth…” (LG, 35). Sharing in the same dream Vidyajyoti has initiated the new Distance Education Programme in Theology (DEPTh) primarily for the lay faithful. It welcomes all who desire to deepen their Christian faith, and search for avenues of deeper participation in the mission of the Church." />
        <meta name="keywords" content="VJDepth - Distance Education Programme in Theology" />
        <link rel="canonical" href="/" />
    </Helmet>
       <Preloader show={loaded ? false : true} />
    <InfoDesk/>
    {/*<MagazineSection />*/}
    <AboutSec />
    <GallerySec/>
    <CoursesDetailsView />
    <ContactUsSec />
    </div>
  )
}
