import React, { useState } from 'react';
import {  useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingUser, faClockRotateLeft, faFilePen, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import {Navbar, Nav, NavbarBrand, NavbarToggler, Collapse, NavItem, NavLink, UncontrolledDropdown,DropdownToggle,DropdownItem,DropdownMenu  } from "reactstrap";
import img from'../assets/vjdepth-logo.png';
//import acredit from'../hospital/ar1.png';


export default function NavbarTop() {
  let navigate=useNavigate();
  const [activeTab, setActiveTab] = useState("Home");
  const [collapsed, setCollapsed] = useState(true);
   // const [isOpen, setIsOpen] = useState(false);
  //const [downcollapse, setdropcollapse]=useState(true);
    const toggleNavbar = () => {
     // console.log("clicked");
        setCollapsed(!collapsed);
    }
/*const toggledropdown = ()=>{
  setdropcollapse(!downcollapse);
}*/

const handleActiveTab = (selectedTab, event, newpath) => {
  event.preventDefault();
  setActiveTab(selectedTab);
  navigate(newpath);
}

const handleReroute=(event, option)=>{
  event.preventDefault();
  if(option==="student"){
  window.location.replace('https://students.vjdepth.in/#/dashboard/sign-in');
  }else if (option==="office"){
    window.location.replace('https://adminoffice.vjdepth.in/#/dashboard/sign-in');
  }
}
   /* const NavbarCollapse = ()=>{
      console.log("clicked222", isOpen);

      setIsOpen(!isOpen);
    }*/

    return( 
     <Navbar color="primary" expand="md" dark className='sticky-top p-0'>   
           <NavbarBrand href="/" style={{marginRight:'0px'}}>
        <h3 className='mb-0 headerText'>
    <img className='img-fluid navbarnew-brand-logo' src={img}   alt="depth-logo" /> DEPTh</h3>
    {/*<span style={{fontSize:'16px'}}>Distance Education Programme in Theology</span> */}
    </NavbarBrand>
  
    <NavbarToggler className="navbarToggleEff me-2"  onClick={toggleNavbar} />
    <div >
    <Collapse isOpen={!collapsed} navbar>
      <Nav className="ml-auto" pills navbar>
        <NavItem >
          <NavLink active={activeTab==="Home"?true:false} onClick={(event)=>handleActiveTab("Home", event, "/")}  >
            Home
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab==="aboutus"?true:false} onClick={(event)=>handleActiveTab("aboutus", event, "/aboutus")}  >
            About Us
          </NavLink>
        </NavItem>
       
        <NavItem>
          <NavLink active={activeTab==="courses"?true:false} onClick={(event)=>handleActiveTab("courses", event, "/courses")}    >
            Courses
          </NavLink>
        </NavItem>
       
        <NavItem>
          <NavLink active={activeTab==="gallery"?true:false} onClick={(event)=>handleActiveTab("gallery", event, "/gallery")}   >
            Gallery
          </NavLink>
        </NavItem>
       
       
       
        <UncontrolledDropdown active={activeTab==="register"?true:false}   inNavbar nav >
          <DropdownToggle caret nav >
            Register
            </DropdownToggle>
            <DropdownMenu >
                <DropdownItem onClick={(event)=>handleActiveTab("register", event, "/registration")}>        
                <FontAwesomeIcon icon={faFilePen} className="me-2" />     
            Register
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={(event)=>handleActiveTab("register", event, "/registration-status")}>
                <FontAwesomeIcon icon={faClockRotateLeft} className="me-2" />
                Application Status
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={(event)=>handleReroute(event, "student")}   >
                <FontAwesomeIcon icon={faUserGraduate} className="me-2" />
                Student Portal
            </DropdownItem>
            </DropdownMenu>
            </UncontrolledDropdown> 

            <UncontrolledDropdown active={activeTab==="signin"?true:false}   inNavbar nav >
          <DropdownToggle caret nav >
            Sign In
            </DropdownToggle>
            <DropdownMenu >
                <DropdownItem onClick={(event)=>handleReroute(event, "student")}   >
                <FontAwesomeIcon icon={faUserGraduate} className="me-2" />
                Student Portal
            </DropdownItem>
                <DropdownItem divider />
            <DropdownItem onClick={(event)=>handleReroute(event,"office")}   >
                <FontAwesomeIcon icon={faBuildingUser} className="me-2" />
                Admin Office
            </DropdownItem>            
            </DropdownMenu>
            </UncontrolledDropdown> 
            <NavItem>
          <NavLink active={activeTab==="contactus"?true:false}   onClick={(event)=>handleActiveTab("contactus", event, "/contactus")} >
          Contact Us
          </NavLink>
        </NavItem>
      </Nav> 
    </Collapse> </div>
    </Navbar>
 );
  
}
